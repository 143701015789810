import {setPicklistData} from "../../../../../functions/datagrid/pick-list-item/setPicklistData";
import {cloneDeep} from "lodash";

export const initializeDatagridPickListItem = async ({vueInstance}) => {
  const {rows, columns} = await setPicklistData({
    pickListId: vueInstance.pickListId,
    criteria: vueInstance.criteria,
    settings: vueInstance.settings,
  });

  vueInstance.rows = rows;
  vueInstance.columns = columns;
  vueInstance.originalRows = cloneDeep(rows);

  vueInstance.loaded = true;
};
