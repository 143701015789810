export function processPartialDeliveryRows({rows, translations}) {
  return rows.map((row) => {
    const rowItemType = row.ItemType?.Value ?? row.ItemType;

    if (rowItemType === "Sales") {
      row.ItemType.Value =
        translations["SettingsTypeArticleSales"] ?? rowItemType;
    } else {
      row.ItemType.Value =
        translations[`ItemType${rowItemType}`] ?? rowItemType;
    }

    return {
      ...row,
      Checked: {
        Name: "Checked",
        Value: false,
        DisableDefaultCheckboxBehaviour: true,
      },
    };
  });
}
