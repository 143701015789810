import {checkRowsPrepared} from "../../../../../functions/datagrid/partialdelivery/checkRowsPrepared";
import {assignValuesToRowsProps} from "../../../../../functions/datagrid/rows/assignValuesToRowsProps";
import {bundleRowsWithCriteria} from "../../../../../functions/datagrid/partialdelivery/bundleRowsWithCriteria";
import {deliverOrderItems} from "../../../../../services/partial-delivery/deliverOrderItems";
import {notify} from "../../../../../util/notify";
import {openDateTimeShippedDialog} from "./openDateTimeShippedDialog";
import {getMinSelectedDateTimeExpectedStart} from "./getMinSelectedDateTimeExpectedStart";
import dayjs from "dayjs";

export const saveDatagridPartialDelivery = async ({vueInstance}) => {
  if (!vueInstance.loaded) return false;
  vueInstance.$store.state.loading = true;
  document.activeElement.blur();

  const isAnyRowPrepared = checkRowsPrepared({
    rows: vueInstance.rows,
    translations: vueInstance.translations,
  });
  if (!isAnyRowPrepared) return false;

  while (vueInstance.loading || vueInstance.loadingPage) {
    await new Promise((r) => setTimeout(r, 0));
  }

  const currentDirtyState = vueInstance.rWindow.data.dirty;
  vueInstance.$emit("data-change", {
    windowId: vueInstance.rWindow.id,
    newData: {
      ...vueInstance.rWindow.data,
      dirty: false,
    },
  });

  const valueRows = assignValuesToRowsProps({rows: vueInstance.rows});
  const filteredRows = valueRows.filter((row) => row.Checked === true);
  const saveRows = bundleRowsWithCriteria({rows: filteredRows});

  const savePartialDelivery = async ({fields}) => {
    const response = await deliverOrderItems({
      rows: saveRows,
      order: vueInstance.order,
      fields,
    });

    if (!response.status === 200) {
      vueInstance.$emit("data-change", {
        windowId: vueInstance.rWindow.id,
        newData: {
          ...vueInstance.rWindow.data,
          dirty: currentDirtyState,
        },
      });
      return false;
    }
    notify({
      message: vueInstance.translations["SaveSuccessful"],
      type: "success",
    });
  };

  if (
    dayjs(getMinSelectedDateTimeExpectedStart({rows: vueInstance.rows})).format(
      "YYYY-MM-DD",
    ) < dayjs().format("YYYY-MM-DD")
  ) {
    return openDateTimeShippedDialog({
      savePartialDelivery,
      rows: vueInstance.rows,
    });
  }

  await savePartialDelivery({});
};
