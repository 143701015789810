var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-end mb-1.5"},[(_vm.loaded && _vm.showScanner)?_c('div',{staticClass:"w-full"},[_c('datagrid-scanner',{attrs:{"params":{
        invoiceId: _vm.invoice.InvoiceID,
        supplierId: _vm.invoice.SupplierID.Key,
      },"filter-by":(option, label, search) => {
          return (
            option.ItemID.toLowerCase().includes(search.toLowerCase()) ||
            option.Description.toLowerCase().includes(search.toLowerCase())
          );
        },"fetch-data-function":_vm.fetchInboundItemScannerData,"settings":_vm.settings,"label":"ItemID"},on:{"select":function($event){return _vm.$emit('addItem', $event)}},scopedSlots:_vm._u([{key:"select-option",fn:function({option, searchValue}){return [_c('item-scanner-label',{attrs:{"option":option,"get-type":_vm.getTypeFromScannedRow,"get-label":_vm.getLabelFromScannedRow,"search-value":searchValue}})]}}],null,false,4210786572)})],1):_vm._e(),_c('datagrid-page-size',{staticClass:"ml-1",attrs:{"page-size":_vm.pageSize},on:{"change":function($event){return _vm.$emit('handlePageSizeChange', $event)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }