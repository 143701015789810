import {convertRowToliRowDataRow} from "../order-item/row/convertRowToliRowDataRow";
import {hasDifferences} from "./updateRows/hasDifferences.js";
import {detectDatagridDataChanges} from "../detectDatagridDataChanges.js";

export const updateRows = ({newRows, oldRows, vueComponent}) => {
  try {
    vueComponent.rows = newRows.map((newRow) => {
      const existingRow = oldRows.find(
        (row) => row.ClientSideUUID.Value === newRow.ClientSideUUID.Value,
      );
      const existingRowInActiveRows = vueComponent.rows.find(
        (row) => row.ClientSideUUID.Value === newRow.ClientSideUUID.Value,
      );

      return existingRow
        ? hasDifferences({obj1: existingRow, obj2: newRow})
          ? {...existingRowInActiveRows, ...newRow} // Merge if difference
          : existingRowInActiveRows // Return existing if no difference
        : newRow; // Otherwise add the new row as-is
    });
  } catch (error) {
    console.error({message: "Error updating rows", oldRows, newRows});
    throw error;
  }

  if (vueComponent.originalRows) {
    const windowData = {
      ...vueComponent.rWindow.data,
      Columns: vueComponent.columns.reduce((acc, column) => {
        acc[column.Name] = column;
        return acc;
      }, {}),
      Rows: vueComponent.rows.map(convertRowToliRowDataRow),
      dirty: detectDatagridDataChanges({
        newRows: vueComponent.rows,
        originalRows: vueComponent.originalRows,
        columns: vueComponent.columns,
      }),
    };

    vueComponent.$emit("data-change", {
      windowId: vueComponent.rWindow.id,
      newData: windowData,
    });
  }
};
