import {processRowForReturn} from "../../../../../functions/datagrid/return-item/processRowForReturn";
import {notify} from "../../../../../util/notify";
import {normalizeValueRows} from "../../../../../functions/datagrid/normalizeValueRows";
import {createReturn} from "../../../../../services/return-item/createReturn";
import {handleReturnResult} from "../../../../../functions/datagrid/return-item/handleReturnResult";

export const saveDatagridReturnItem = async ({vueInstance}) => {
  if (vueInstance.loading) return false;
  vueInstance.$store.state.loading = true;
  document.activeElement.blur();

  while (vueInstance.loading || vueInstance.loadingPage) {
    await new Promise((r) => setTimeout(r, 0));
  }

  const returnItems = vueInstance.rows
    .filter((row) => row.Checked.Value)
    .map((row) => {
      return processRowForReturn({row, formFields: vueInstance.formFields});
    });

  if (!returnItems.length || !returnItems) {
    notify({
      type: "warning",
      message: vueInstance.translations.AdminDesktop_AlertNoRowsSelected,
    });
    return false;
  }
  const currentDirtyState = vueInstance.rWindow.data.dirty;
  vueInstance.$emit("data-change", {
    windowId: vueInstance.rWindow.id,
    newData: {
      ...vueInstance.rWindow.data,
      dirty: false,
    },
  });

  const normalizedReturnRows = normalizeValueRows({rows: returnItems});

  const result = await createReturn({returnItems: normalizedReturnRows});

  await handleReturnResult({
    result,
    currentDirtyState,
    vueComponent: vueInstance,
  });
};
