import dayjs from "dayjs";
import {getPrice} from "../../../../services/v2/rental/quotation/getPrice";

export const processRowPriceCalculation = async ({row, quotation}) => {
  const startDate = dayjs(row.DateTimeBusinessStart?.Value).format(
    "YYYY-MM-DDTHH:mm:ss",
  );
  const endDate = dayjs(row.DateTimeBusinessEnd?.Value).format(
    "YYYY-MM-DDTHH:mm:ss",
  );
  const priceResult = await getPrice({
    startDate,
    endDate,
    itemId: row.ItemID.Value,
    customerId: quotation.CustomerID?.Key,
    transactionTypeId: quotation.TransactionTypeID?.Key,
    contractId: quotation.ContractID?.Key,
  });

  return {
    ...row,
    PriceProjection: {
      ...row.PriceProjection,
      Value: {PriceCalculation: priceResult.PriceCalculation ?? []},
    },
  };
};
