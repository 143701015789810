import {getWindowFromWindowId} from "./getWindowFromWindowId";
import store from "../../../state/store";

export const getIframeUrlFromWindow = ({rWindow}) => {
  const window = getWindowFromWindowId({
    windowId: rWindow.id,
    session: global.session,
  });
  if (!window) return rWindow?.data?.Url ?? "";

  const targetWindow = window.parent ?? window;
  let legacyWindowUrl = "undefined";

  if (targetWindow.output.Request.MenuIframe) {
    legacyWindowUrl = targetWindow.output?.Request?.Url?.includes("app.powerbi")
      ? `${targetWindow.output.Request.Url}`
      : `${targetWindow.output.Request.Url}?token=${store.state.accessToken}`;
  } else {
    legacyWindowUrl = targetWindow.output?.Request?.Criteria[0]?.Url?.includes(
      "app.powerbi",
    )
      ? `${targetWindow.output.Request.Criteria[0].Url}`
      : `${targetWindow.output.Request.Criteria[0].Url}&Authorization=Bearer ${store.state.accessToken}`;
  }

  return legacyWindowUrl.includes("undefined")
    ? rWindow.data.Url
    : legacyWindowUrl;
};
