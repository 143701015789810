import {fetchScannedValueRowData} from "@/functions/datagrid/quotation-item/fetchScannedValueRowData";
import {processQuotationItemRowMetaData} from "@/functions/datagrid/quotation-item/row/processQuotationItemRowMetaData";
import {convertKeyValueRowToCellRow} from "../../row/convertKeyValueRowToCellRow";
import {addMissingPropsToRow} from "@/functions/datagrid/quotation-item/row/addMissingPropsToRow";
import {getRawValueFromDropdownValue} from "@/util/parsing/getRawValueFromDropdownValue";
import {setCorrectCompositionValue} from "../../order-item/row/setCorrectCompositionValue";
import {setClientSideUUIDToRow} from "@/functions/datagrid/row/setClientSideUUIDToRow";
import {normalizeRowKeyObjects} from "@/functions/datagrid/row/normalizeRowKeyObjects";

export async function generateNewRowFromScanItem({
  item,
  ranking,
  rows,
  oldRow,
  quotation,
}) {
  const criteria = [
    {
      IsReplacement: false,
      QuotationID: quotation.QuotationID,
      Target: "Rental.Quotation",
    },
  ];
  const scannedRowsData = await fetchScannedValueRowData({
    scannedValue: item.value,
    criteria: criteria,
  });

  return await Promise.all(
    scannedRowsData.Rows.map(async (row, index) => {
      row = normalizeRowKeyObjects({row});
      if (!ranking && rows.length > 0) {
        const rowWithHighestRanking = rows.reduce((prev, current) =>
          prev.Ranking.Value > current.Ranking.Value ? prev : current,
        );
        const currentRanking =
          parseInt(rowWithHighestRanking.Ranking.Value) + 10;
        const nextRanking = Math.floor(currentRanking / 10) * 10;

        ranking = nextRanking;
      }

      if (!ranking) {
        ranking = 10;
      }

      row.QuotationItemID = null;
      row.Ranking = ranking;

      if (index > 0) {
        row.Ranking = row.Ranking + index * 10;
      }

      const itemType = getRawValueFromDropdownValue(row.ItemType);

      if (itemType === "Item") {
        row.ItemID = item.value;
      }
      if (itemType === "ItemSerial") {
        row.SerialID = item.value;
      }

      if (oldRow?.ClientSideUUID?.Value) {
        row.ClientSideUUID = oldRow.ClientSideUUID;
        row.QuotationItemID = oldRow.QuotationItemID;
      } else {
        row = setClientSideUUIDToRow({row});
      }

      row = convertKeyValueRowToCellRow(row);
      row = await processQuotationItemRowMetaData({row, quotation});

      row = addMissingPropsToRow(row);
      const cellItemType = getRawValueFromDropdownValue(row.ItemType.Value);

      if (cellItemType === "ItemSerial") {
        row.Amount.IsReadOnly = true;
      }

      if (row.Composition.Value) {
        row = setCorrectCompositionValue({row, rows});
      }

      row.ItemID.OriginalValue = row.ItemID.Value;
      return row;
    }),
  );
}
