import {getRawValueFromDropdownValue} from "../../../../util/parsing/getRawValueFromDropdownValue";
import {getRowsFromScannedItem} from "../getRowsFromScannedItem";

export const handleItemIdChange = async ({row, rows, invoice, columns}) => {
  const itemType = getRawValueFromDropdownValue(row.ItemType?.Value);
  if (itemType === "Free") return;

  const item = {
    type: "Item",
    value: row.ItemID.Value,
  };

  const [newRow, ...extraRows] = await getRowsFromScannedItem({
    item,
    invoice,
    rows,
    columns,
  });

  let newRows = rows.map((r) => {
    if (r.Ranking.Value === row.Ranking.Value) {
      return {
        ...newRow,
        Ranking: {...newRow.Ranking, Value: row.Ranking.Value},
      };
    }

    return r;
  });

  return [...newRows, ...extraRows];
};
