import {getUndeliveredOrderItems} from "./getUndeliverdOrderItems.js";
import {convertUndeliveredOrderItemsToRows} from "./convertUndeliveredOrderItemsToRows.js";
import {setClientSideUUIDToRows} from "@/functions/datagrid/rows/setClientSideUUIDToRows";
import {rowsSetNullValueForMissingProps} from "@/functions/datagrid/rows/rowsSetNullValueForMissingProps";
import {rowsSetMaxAmountRejected} from "./rows/rowsSetMaxAmountRejected.js";
import {rowsSetDefaultAmountReceived} from "./rows/rowsSetDefaultAmountReceived.js";
import {rowsSetDefaultAmountRejected} from "./rows/rowsSetDefaultAmountRejected.js";
import {sortByFilledInRows} from "./rows/sortByFilledInRows";
import {processInboundDeliveryRowsMetaData} from "./rows/processInboundDeliveryRowsMetaData.js";

export const getAndProcessRows = async ({columns}) => {
  let undeliveredOrderItems = await getUndeliveredOrderItems();

  let rows = await convertUndeliveredOrderItemsToRows({
    undeliveredOrderItems,
  });

  rows = setClientSideUUIDToRows({rows});
  rows = rowsSetNullValueForMissingProps({rows, columns});
  rows = rowsSetMaxAmountRejected({rows});
  rows = rowsSetDefaultAmountReceived({rows});
  rows = rowsSetDefaultAmountRejected({rows});
  rows = processInboundDeliveryRowsMetaData({rows});
  rows = sortByFilledInRows({rows});

  return rows;
};
