import {getColumns} from "../../../../../functions/datagrid/columns/getColumns";
import {processColumnTypes} from "../../../../../functions/datagrid/processColumnTypes";
import {processColumnsMetaData} from "../../../../../functions/datagrid/partialdelivery/processColumnsMetaData";
import {sortColumns} from "../../../../../functions/datagrid/columns/sortColumns";
import {convertKeyValueRowsToCellRows} from "../../../../../functions/datagrid/rows/convertKeyValueRowsToCellRows";
import {setClientSideUUIDToRows} from "../../../../../functions/datagrid/rows/setClientSideUUIDToRows";
import {processPartialDeliveryRows} from "../../../../../functions/datagrid/partialdelivery/processPartialDeliveryRows";
import {cloneDeep} from "lodash";
import {setInitialDatagridDataToWindow} from "../../../../../functions/datagrid/data/setInitialDatagridDataToWindow";

export const initializeDatagridPartialDelivery = async ({vueInstance}) => {
  let columns = await getColumns({
    table: "Rental.virtual_PartialDelivery",
    primaryKey: "OrderID",
    prefix: "Multi",
  });

  columns = processColumnTypes({columns});
  columns = processColumnsMetaData({columns});
  vueInstance.columns = sortColumns(columns);

  let rows = Object.values(
    vueInstance.originalRows.length > 0
      ? vueInstance.originalRows
      : vueInstance.rWindow.data.Rows,
  );

  rows = convertKeyValueRowsToCellRows(rows);
  rows = setClientSideUUIDToRows({rows});
  rows = processPartialDeliveryRows({
    rows,
    translations: vueInstance.translations,
  });
  vueInstance.rows = rows;
  vueInstance.originalRows = cloneDeep(vueInstance.rows);

  setInitialDatagridDataToWindow({
    window: vueInstance.rWindow,
    columns: vueInstance.columns.reduce((acc, column) => {
      acc[column.Name] = column;
      return acc;
    }, {}),
    rows: vueInstance.rows,
    vueInstance,
  });

  vueInstance.loaded = true;
};
