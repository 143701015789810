<template>
  <div
    class="bg-white"
    :class="{'opacity-60 pointer-events-none': loadingPage}"
  >
    <div
      v-if="!loaded || loadingPage"
      class="loader w-full flex justify-center"
    >
      <i class="fas fa-spinner fa-spin text-3xl mt-3"></i>
    </div>
    <div ref="datagrid">
      <datagrid-standalone
        v-if="loaded && columns.length"
        :rows="rows"
        :actions="actions"
        :columns="columns"
        :sort-by="sortBy"
        :sort-direction="sortDirection"
        :window-id="rWindow.id"
        :user-metadata-key="userMetadataKey"
        :page="page"
        :visible-row-function="getVisibleRowsCompositionGrouped"
        :page-size="pageSize"
        :process-context="{quotation}"
        :warehouse-id="warehouseId"
        :action-functions="actionFunctions"
        :skeleton-rows="addItemQueue.length"
        @click-row="handleRowClick"
        @loading="loading = $event"
        @rows-updated="handleRowsUpdated($event)"
        @cell-icon-click="handleCellIconClick($event)"
        @page-size-change="handlePageSizeChange"
        @page-change="handlePageChange({page: $event})"
      >
        <template #header>
          <datagrid-header-quotation-item
            :loaded="loaded"
            :entity="entity"
            :scan-amount="scanAmount"
            :settings="settings"
            :page-size="pageSize"
            @scan-amount-change="handleScanAmountChange"
            @item-select="addItem"
            @dialog-toggle="showItemPickerDialog = !showItemPickerDialog"
            @page-size-change="handlePageSizeChange"
          />
        </template>
        <template #footer>
          <datagrid-quotation-item-footer
            :rows="rows"
            :loading-page="loadingPage"
            :total-weights="totalWeights"
            :extra-total-values="extraTotalValues"
            :page="page"
            :page-size="pageSize"
            :active-row="activeRow"
            :currency="currency"
            :default-currency-id="defaultCurrencyId"
            @page-change="handlePageChange({page: $event})"
          />
        </template>
      </datagrid-standalone>
    </div>
  </div>
</template>

<script>
import {notify} from "../../../../util/notify";
import {cloneDeep} from "lodash";
import {getUserId} from "../../../../functions/session/getUserId";
import {getTranslations} from "../../../../functions/session/localstorage/getTranslations.js";
import {mapActionComponent} from "../../../../functions/datagrid/mapActionComponent";
import {addNewRows} from "../../../../functions/datagrid/quotation-item/addNewRows";
import {initializeDatagridQuotationItem} from "./utils/initializeDatagridQuotationItem";
import {sortRows} from "../../../../functions/datagrid/rows/sortRows";
import {loadRowDataForPage} from "../../../../functions/datagrid/quotation-item/loadRowDataForPage";
import {integrateRowsChanges} from "../../../../functions/datagrid/rows/integrateRowsChanges";
import {getVisibleRowsCompositionGrouped} from "../../../../functions/datagrid/rows/getVisibleRowsCompositionGrouped";
import {handleRowsUpdated} from "../../../../functions/datagrid/quotation-item/event-handlers/handleRowsUpdated.js";
import handleRowDeleteCompositionSupport from "../../../../functions/datagrid/row/handleRowDeleteCompositionSupport";
import {applyRentalQuotationItemPricesUpdatedDraftHook} from "../../../../functions/datagrid/quotation-item/rows/applyRentalQuotationItemPricesUpdatedDraftHook";
import {getDataWebhooks} from "../../../../functions/session/localstorage/getDataWebhooks";
import {getExtraTotalValues} from "../../../../functions/datagrid/quotation-item/rows/getExtraTotalValues";
import {prepareRowsForRender} from "../../../../functions/datagrid/rows/prepareRowsForRender";
import {getTotalWeightFromRows} from "../../../../functions/datagrid/rows/getTotalWeightFromRows.js";
import {handleJobExecution} from "../../../../functions/datagrid/actions/handleJobExecution.js";
import {confirmDiscardUnsavedChanges} from "../../../../interface/prompts/confirmDiscardUnsavedChanges.js";
import {updateRows} from "../../../../functions/datagrid/rows/updateRows.js";
import {saveDatagridQuotationItem} from "./utils/saveDatagridQuotationItem";
import DatagridHeaderQuotationItem from "../../datagridHeaders/DatagridHeaderQuotationItem.vue";
import DatagridQuotationItemFooter from "../../datagridFooters/DatagridQuotationItemFooter.vue";
import DatagridStandalone from "../../DatagridStandalone.vue";

export default {
  name: "DatagridQuotationItem",
  components: {
    DatagridHeaderQuotationItem,
    DatagridQuotationItemFooter,
    DatagridStandalone,
  },
  provide() {
    const vm = this;

    const rate = {};
    Object.defineProperty(rate, "rate", {
      enumerable: true,
      get: () => this.rate,
    });

    return {
      rate,
      translations: this.translations,
      rateCallback(row, followSetting = true) {
        if (vm.selectedCurrencyId === vm.defaultCurrencyId && followSetting)
          return 1;
        return row?.Rate?.Value ?? 1;
      },
    };
  },
  props: {
    rWindow: {
      type: Object,
      required: true,
    },
    rParentWindow: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    settings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      entity: "DatagridQuotationItem",
      quotationId: null,
      quotation: {},
      criteria: [],
      rows: [],
      originalRows: [],
      columns: {},
      page: 1,
      pageSize: 10,
      activeRow: null,
      showItemPickerDialog: false,
      selectedItemId: null,
      addItemQueue: [],
      scanAmount: 1,
      loading: false,
      loaded: false,
      loadingPage: false,
      defaultCurrencyId: global.session?.defaultCurrencyId,
      rentalQuotationItemPricesUpdatedDraftWebhookEvent: false,
      extraTotalValues: {},
      sortBy: "Ranking",
      sortDirection: "asc",
      translations: getTranslations(),
      actions: [{type: "Delete"}].map(mapActionComponent),
      actionFunctions: {
        deleteRow: handleRowDeleteCompositionSupport,
      },
    };
  },
  computed: {
    totalWeights() {
      return getTotalWeightFromRows({rows: this.rows});
    },
    rate() {
      return this.rows[0]?.Rate?.Value ?? 1;
    },
    currency() {
      return (
        this.quotation?.CurrencyID?.Description ??
        this.defaultCurrencyId ??
        null
      );
    },
    selectedCurrencyId() {
      return (
        this.rWindow.request.Data?.ClientCurrency ?? this.defaultCurrencyId
      );
    },
    userMetadataKey() {
      const userId = getUserId();
      return `${userId}-Rental.QuotationItem-Multi`;
    },
    warehouseId() {
      return this.quotation?.WarehouseID?.Key ?? this.quotation?.WarehouseID;
    },
  },
  async created() {
    global.eventBus.on(`new-job-${this.rWindow.id}`, this.handleJobExecution);
    this.dataWebhooks = getDataWebhooks();
    this.rentalQuotationItemPricesUpdatedDraftWebhookEvent =
      this.dataWebhooks.some(
        (webhook) =>
          webhook.EventID === "rental.quotationitem.prices.updated.draft",
      );

    this.criteria = this.rWindow.criteria ?? this.rParentWindow.criteria;
    this.quotationId = this.criteria[0].QuotationID;

    await initializeDatagridQuotationItem({vueInstance: this});
  },
  beforeDestroy() {
    global.eventBus.off(`new-job-${this.rWindow.id}`, this.handleJobExecution);
  },
  methods: {
    async addItem(item) {
      const datagridElement =
        this.$refs.datagrid.querySelector(".datagrid-table");

      this.addItemQueue.push(item);
      if (this.addItemQueue.length > 1) {
        return;
      }

      while (this.addItemQueue.length > 0) {
        try {
          const queuedItem = this.addItemQueue[0];
          const newRows = await addNewRows({
            item: queuedItem,
            columns: this.columns,
            rows: this.rows,
            quotation: this.quotation,
            settings: this.settings,
            scanAmount: this.scanAmount,
            vueComponent: this,
          });

          const oldRows = cloneDeep(this.rows);
          this.updateRows({newRows, oldRows, vueComponent: this});
        } catch (error) {
          console.error(error);
        } finally {
          this.addItemQueue.shift();
        }
      }

      let rows = this.rows;
      rows = prepareRowsForRender({rows, columns: this.columns});
      this.rows = rows;

      rows = await applyRentalQuotationItemPricesUpdatedDraftHook({
        rows,
        quotation: this.quotation,
        dataWebHooks: this.dataWebhooks,
      });

      if (this.rows !== rows) {
        rows = prepareRowsForRender({rows, columns: this.columns});
        this.rows = rows;
      }

      this.extraTotalValues = {
        ...(await getExtraTotalValues({
          rows: this.rows,
          quotation: this.quotation,
          dataWebHooks: this.dataWebhooks,
        })),
      };

      this.navigateToLastPage();
      this.scrollToBottom(datagridElement);
    },
    async save(job) {
      try {
        if (!this.loaded) return false;

        this.$store.state.loading = true;
        document.activeElement.blur();

        while (this.loading || this.loadingPage) {
          await new Promise((r) => setTimeout(r, 0));
        }

        await saveDatagridQuotationItem({vueInstance: this});
      } finally {
        this.$store.state.loading = false;
        this.$emit("job-completed", job);
      }
      return false;
    },
    async reset(job) {
      try {
        const rowsAreEqual =
          JSON.stringify(this.rWindow.data.Rows) ===
          JSON.stringify(this.rWindow.initialData.Rows);
        if (!rowsAreEqual && !(await confirmDiscardUnsavedChanges())) {
          return false;
        }

        this.$store.state.loading = true;
        await initializeDatagridQuotationItem({vueInstance: this});
      } finally {
        this.$store.state.loading = false;
        this.$emit("job-completed", job);
      }
      return false;
    },
    async sortRows(job) {
      this.rows = sortRows(this.rows);
      notify({message: this.translations["RowsSorted"], type: "success"});
      this.$emit("job-completed", job);
      return false;
    },
    handleRowsUpdated(event) {
      handleRowsUpdated({...event, vueComponent: this});
    },
    updateRows,
    handleScanAmountChange(scanAmount) {
      this.scanAmount = scanAmount.target.value;
    },
    async handlePageChange({page}) {
      this.page = page;
      this.rows = await this.loadAndApplyRowDataForPage({
        rows: this.rows,
        page,
        pageSize: this.pageSize,
        quotation: this.quotation,
      });
    },
    handlePageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.navigateToPage({page: 1});
    },
    scrollToBottom(element) {
      element.scrollTop = element.scrollHeight;
    },
    navigateToLastPage() {
      const rowCount = this.rows.filter(
        (row) => !row.rowMeta?.compositionItem,
      ).length;
      this.page = Math.ceil(rowCount / this.pageSize);
    },
    navigateToPage({page}) {
      this.page = page;
    },
    async loadAndApplyRowDataForPage() {
      const changedRowsObject = await loadRowDataForPage({
        rows: this.rows,
        page: this.page,
        pageSize: this.pageSize,
        quotation: this.quotation,
      });

      return integrateRowsChanges({
        originalRows: this.rows,
        changedRows: changedRowsObject,
      });
    },
    handleCellIconClick({row, cell}) {
      if (cell.Name === "Amount") {
        this.selectedItemId = row.ItemID.Value;
        this.showItemPickerDialog = true;
        setTimeout(() => {
          this.selectedItemId = null;
        }, 300);
      }
    },
    handleRowClick(row) {
      this.updateActiveRow(row);
    },
    updateActiveRow(row) {
      this.activeRow = row;
    },
    getVisibleRowsCompositionGrouped,
    handleJobExecution() {
      for (const job of this.rWindow.jobs) {
        handleJobExecution({job, vueInstance: this});
      }
    },
  },
};
</script>

<style scoped lang="scss">
.form-input {
  padding: 0;
}
.btn-group {
  .input-group-append {
    box-shadow: 1px 1px 1px 0px rgb(0 0 0 / 9%) !important;
    border: 1px solid #ced4da;
    height: 31.5px !important;
    padding-right: 5px !important;
    padding-left: 5px !important;
    margin-left: -2px;
    :hover {
      background-color: #eeeeee;
    }
  }
  .input-group-append:hover {
    background-color: #eeeeee;
    cursor: default;
  }
}
</style>
