<template>
  <div id="canvas" :class="classes" :style="styleProperties">
    <canvas-header
      :window="window"
      :active-r-window="activeRWindow"
      :active-r-sub-window="activeRSubWindow"
      @new-job="$emit('new-job', $event)"
    />

    <div v-show="activeRWindow.vueComponent">
      <div v-for="rWindow in parentWindows" :key="rWindow.id">
        <r-window
          v-if="rWindow.vueComponent"
          v-show="rWindow.id === activeRWindow.id"
          :r-window="rWindow"
          :settings="settings"
          :r-windows="rWindows"
          @window-change="$emit('window-change', $event)"
          @data-change="$emit('data-change', $event)"
          @initial-data-change="$emit('initial-data-change', $event)"
          @job-completed="$emit('job-completed', $event)"
        />
      </div>
    </div>

    <div data-window-anchor></div>
  </div>
</template>

<script>
import CanvasHeader from "./CanvasHeader.vue";
import RWindow from "../window-types/RWindow.vue";

export default {
  components: {
    CanvasHeader,
    RWindow,
  },
  props: {
    rWindows: {
      type: Object,
      required: true,
    },
    window: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    settings: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    activeRWindow: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    activeRSubWindow: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  computed: {
    parentWindows() {
      return Object.values(this.rWindows).filter(
        (rWin) => !rWin.parentWindowId,
      );
    },
    loading: function () {
      return this.$store.state.loading;
    },
    styleProperties: function () {
      if (this.loading) {
        return {
          opacity: 0.4,
          "pointer-events": "none",
        };
      }
      return {};
    },
    classes: function () {
      if (this.$store.state.settings.SimpleInterface) {
        return "simple-interface";
      }
      return "advanced-interface";
    },
  },
};
</script>
