<template>
  <div class="bg-white" :class="{'opacity-60 pointer-events-none': loading}">
    <div v-if="loading" class="loader w-full flex justify-center">
      <i class="fas fa-spinner fa-spin text-3xl mt-3"></i>
    </div>
    <div v-if="!loading">
      <div ref="datagrid">
        <datagrid-standalone
          v-if="!loading"
          :rows="rows"
          :columns="columns"
          :user-metadata-key="userMetadataKey"
          :page="page"
          :filters="selectedFilterValues"
          :page-size="pageSize"
          @page-change="page = $event"
          @rows-updated="handleRowsUpdated"
        >
          <template #header>
            <datagrid-header-inbound-delivery
              class="mb-2"
              :rows="rows"
              :columns="columns"
              :filters="filters"
              :page-size="pageSize"
              @page-size-change="handlePageSizeChange"
              @filter-change="handleFilterChange($event)"
            />
          </template>
        </datagrid-standalone>
      </div>
    </div>
  </div>
</template>

<script>
import {initializeDatagridInboundDelivery} from "./utils/initializeDatagridInboundDelivery";
import {getDatagridGroupFilterOptions} from "../../../../functions/datagrid/filters/getDatagridGroupFilterOptions.js";
import {confirmDiscardUnsavedChanges} from "../../../../interface/prompts/confirmDiscardUnsavedChanges.js";
import {saveDatagridInboundDelivery} from "./utils/saveDatagridInboundDelivery";
import {detectDatagridDataChanges} from "../../../../functions/datagrid/detectDatagridDataChanges.js";
import {convertRowToliRowDataRow} from "../../../../functions/datagrid/order-item/row/convertRowToliRowDataRow.js";
import {sortByFilledInRows} from "../../../../functions/datagrid/undelivered-order-items/rows/sortByFilledInRows";
import {handleJobExecution} from "../../../../functions/datagrid/actions/handleJobExecution.js";
import {getTranslations} from "../../../../functions/session/localstorage/getTranslations.js";
import {getUserId} from "../../../../functions/session/getUserId";
import {notify} from "../../../../util/notify";
import DatagridHeaderInboundDelivery from "../../datagridHeaders/DatagridHeaderInboundDelivery.vue";
import DatagridStandalone from "../../DatagridStandalone.vue";

export default {
  name: "DatagridInboundDelivery",
  components: {
    DatagridHeaderInboundDelivery,
    DatagridStandalone,
  },
  props: {
    rWindow: {
      type: Object,
      required: true,
    },
    rParentWindow: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    settings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rows: [],
      originalRows: [],
      orderId: "",
      columns: [],
      loading: true,
      page: 1,
      pageSize: 10,
      filters: {},
      pageRendered: false,
      translations: getTranslations(),
    };
  },
  computed: {
    userMetadataKey() {
      const userId = getUserId();
      return `${userId}-Inbound.virtual_Delivery-Multi`;
    },
    selectedFilterValues() {
      return {
        OrderID: Object.values(this.filters).reduce(
          (acc, filter) => acc.concat(filter.selectedValues),
          [],
        ),
      };
    },
  },
  async created() {
    global.eventBus.on(`new-job-${this.rWindow.id}`, this.handleJobExecution);
    this.orderId =
      this.rWindow.criteria?.[0]?.Order ??
      this.rParentWindow.criteria?.[0]?.Order;

    await initializeDatagridInboundDelivery({vueInstance: this});
  },
  beforeDestroy() {
    global.eventBus.off(`new-job-${this.rWindow.id}`, this.handleJobExecution);
  },
  methods: {
    async processDelivery(job) {
      try {
        if (!this.pageRendered) return false;
        this.$store.state.loading = true;

        await saveDatagridInboundDelivery({vueInstance: this});
      } catch {
        notify({
          message: this.translations.NotifyFailedToProcessDelivery,
          type: "error",
        });
      } finally {
        this.$store.state.loading = false;
        this.$emit("job-completed", job);
      }
      return false;
    },
    async reset(job) {
      try {
        const rowsAreEqual =
          JSON.stringify(this.rWindow.data.Rows) ===
          JSON.stringify(this.rWindow.initialData.Rows);

        if (!rowsAreEqual && !(await confirmDiscardUnsavedChanges())) {
          return false;
        }
        this.$store.state.loading = true;

        this.page = 1;
        this.rows = [];
        this.columns = [];

        for (const filter in this.filters) {
          this.filters[filter].selectedValues = [];
        }

        await initializeDatagridInboundDelivery({vueInstance: this});
      } finally {
        this.$store.state.loading = false;
        this.$emit("job-completed", job);
      }
      return false;
    },
    setFilters() {
      this.filters = {
        OrderID: {
          title: this.translations.OrderID,
          name: "OrderID",
          selectedValues: [],
          options: getDatagridGroupFilterOptions({
            rows: this.rows,
            groupNodeColumnName: "SupplierDescription",
            childNodeColumnName: "OrderID",
          }),
        },
      };
    },
    handleRowsUpdated({rows}) {
      this.rows = sortByFilledInRows({rows});
      this.updateWindowData();
    },
    updateWindowData() {
      const windowData = {
        ...this.rWindow.data,
        Columns: this.columns.reduce((acc, column) => {
          acc[column.Name] = column;
          return acc;
        }, {}),
        Rows: this.rows.map(convertRowToliRowDataRow),
        dirty: detectDatagridDataChanges({
          newRows: this.rows,
          originalRows: this.originalRows,
          columns: this.columns,
        }),
      };

      this.$emit("data-change", {
        windowId: this.rWindow.id,
        newData: windowData,
      });
    },
    handleFilterChange(filter) {
      const filterName = Object.keys(filter)[0];

      this.filters[filterName].selectedValues = filter[filterName];
    },
    handlePageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.navigateToPage({page: 1});
    },
    navigateToPage({page}) {
      this.page = page;
    },
    handleJobExecution() {
      for (const job of this.rWindow.jobs) {
        handleJobExecution({job, vueInstance: this});
      }
    },
  },
};
</script>
