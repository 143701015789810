import {notifyUserAboutFirstRowMissingRequiredValue} from "../../../../../functions/datagrid/inbound-orderitem/notifyUserAboutFirstRowMissingRequiredValue";
import {validateInboundValueOnSave} from "../../../../../functions/datagrid/inbound-orderitem/rows/validateInboundValueOnSave";
import {notify} from "../../../../../util/notify";
import {cloneDeep} from "lodash";
import {saveInboundOrderItems} from "../../../../../services/inbound/saveInboundOrderItems";

export const saveDatagridInboundOrderItem = async ({vueInstance}) => {
  if (!vueInstance.loaded) return false;
  vueInstance.$store.state.loading = true;

  const missingCellRowsObject = vueInstance.processEmptyRequiredCellNames({
    rows: vueInstance.rows,
    columns: vueInstance.columns,
  });
  if (Object.keys(missingCellRowsObject).length) {
    notifyUserAboutFirstRowMissingRequiredValue({
      rows: vueInstance.rows,
      columns: vueInstance.columns,
      missingCellRowsObject,
    });
    return false;
  }

  document.activeElement.blur();
  while (vueInstance.loading) {
    await new Promise((r) => setTimeout(r, 0));
  }

  const validationError = validateInboundValueOnSave({
    rows: vueInstance.rows,
    columns: vueInstance.columns,
  });
  if (validationError) {
    notify({
      message: validationError,
      type: "error",
    });
    return false;
  }

  const currentDirtyState = vueInstance.rWindow.data.dirty;
  vueInstance.$emit("data-change", {
    windowId: vueInstance.rWindow.id,
    newData: {
      ...vueInstance.rWindow.data,
      dirty: false,
    },
  });

  const observerLessRows = cloneDeep(vueInstance.rows);
  const serverRows = observerLessRows.map((row) => {
    const serverRow = {};
    Object.keys(row).forEach((key) => {
      if (key === "ClientSideUUID") {
        return;
      }
      if (key === "RentalItemID") {
        delete serverRow[key];
        return;
      }
      serverRow[key] = row[key].Value;
    });
    return serverRow;
  });

  const saved = await saveInboundOrderItems({
    rows: serverRows,
    orderId: vueInstance.orderId,
  });

  if (saved) {
    notify({
      message: vueInstance.translations["SaveSuccessful"],
      type: "success",
    });
    global.session.windows[vueInstance.rWindow.id].parent.action(
      "InboundOrderItem <-> InboundOrder",
      "Inbound.Order",
      "/Admin/WebServices/CoreWebServices.asmx",
      "OpenRelation",
    );
  } else {
    vueInstance.$emit("data-change", {
      windowId: vueInstance.rWindow.id,
      newData: {
        ...vueInstance.rWindow.data,
        dirty: currentDirtyState,
      },
    });
    notify({
      message: vueInstance.translations["SaveFailed"],
      type: "warning",
    });
  }
};
