import {getAndSetColumns} from "../../../../../functions/datagrid/undelivered-order-items/getAndSetColumns";
import {getAndProcessRows} from "../../../../../functions/datagrid/undelivered-order-items/getAndProcessRows";
import {cloneDeep} from "lodash";
import {setInitialDatagridDataToWindow} from "../../../../../functions/datagrid/data/setInitialDatagridDataToWindow";

export const initializeDatagridInboundDelivery = async ({vueInstance}) => {
  const columns = await getAndSetColumns({
    table: "Inbound.DeliveryItem",
    prefix: "MultiEdit",
  });
  const rows = await getAndProcessRows({
    columns,
  });
  const clonedRows = cloneDeep(rows);

  vueInstance.columns = columns;
  vueInstance.rows = clonedRows;
  vueInstance.originalRows = clonedRows;

  setInitialDatagridDataToWindow({
    window: vueInstance.rWindow,
    columns: columns.reduce((acc, column) => {
      acc[column.Name] = column;
      return acc;
    }, {}),
    rows: clonedRows,
    vueInstance,
  });

  vueInstance.setFilters();

  vueInstance.loading = false;
  setTimeout((vueInstance.pageRendered = true), 200);
};
