import {assingLastSelectedLocationToDropdown} from "../../../../../functions/datagrid/return-item/assingLastSelectedLocationToDropdown.js";
import {assignBusinessHoursToDropdown} from "../../../../../functions/datagrid/return-item/assignBusinessHoursToDropdown.js";
import {assignLocationsToDropdown} from "../../../../../functions/datagrid/return-item/assignLocationsToDropdown.js";
import {getReturnHours} from "../../../../../services/return-item/getReturnHours.js";
import {getLocations} from "../../../../../services/logistics/getLocations.js";
import {getOptions} from "../../../../../functions/fields/select-field/getOptions.js";
import {getUserId} from "../../../../../functions/session/getUserId.js";
import dayjs from "dayjs";

export const initializeReturnItemFormFields = async ({
  window,
  settings,
  translations,
}) => {
  const {DisplayBusinessHourEnd, OnlyShowScan} = window.data;
  const {DefaultSelectedReturnType, CreateReturnFocusScanOnOpen, UseLocations} =
    settings ?? null;
  const userId = getUserId();

  let locations = [];
  if (UseLocations) {
    const result = await getLocations({params: {receipt: true}});
    locations = result.data?.Collection ?? [];
  }

  let businessHours = [];
  if (DisplayBusinessHourEnd) {
    businessHours = await getReturnHours({
      businessDay: dayjs().format("YYYY-MM-DD"),
    });
  }

  let returnItemFormFields = {};

  if (!OnlyShowScan) {
    returnItemFormFields.Type = {
      Name: "Type",
      RawTitle: "Type",
      Type: "Select",
      Dropdown: {
        Items: await getOptions({
          field: {
            Dropdown: {
              TableName: "Rental.vw_ReturnType",
              ColumnName: "ReturnType",
            },
          },
        }),
      },
      IsVisible: true,
      IsReadOnly: !!DefaultSelectedReturnType,
      Value: null,
      ColumnWidth: 2,
      Ranking: 10,
    };
    returnItemFormFields.Value = {
      Name: "Value",
      RawTitle: "Value",
      Type: "Select",
      Dropdown: {
        Items: [],
        TableName: "Rental.OrderItem",
        ColumnName: "ReturnValue",
        AutoFetchOptions: false,
        MultiSelect: false,
      },
      Filter: {
        ReturnType:
          DefaultSelectedReturnType && DefaultSelectedReturnType !== "All"
            ? DefaultSelectedReturnType
            : null,
      },
      IsVisible: true,
      Editor: "Select",
      IsReadOnly: !(
        DefaultSelectedReturnType && DefaultSelectedReturnType !== "All"
      ),
      Value: null,
      ColumnWidth: 2,
      Ranking: 20,
    };
  }

  returnItemFormFields.Scan = {
    Name: "Scan",
    RawTitle: "Scan",
    Type: "Select",
    Value: null,
    Dropdown: {
      Items: [],
      ScannerMode: true,
      HideNoOptionsPlaceholder: true,
      AutoFocus: CreateReturnFocusScanOnOpen,
    },
    IsReadOnly: false,
    IsVisible: true,
    ColumnWidth: 2,
    Ranking: 30,
  };

  if (DisplayBusinessHourEnd)
    returnItemFormFields.Notes = {
      Name: "Notes",
      RawTitle: "Notes",
      Type: "Text",
      Value: "",
      IsVisible: true,
      IsReadOnly: false,
      ColumnWidth: 2,
      Ranking: 120,
    };

  returnItemFormFields = {
    ...returnItemFormFields,
    DateTimeReturned: {
      Name: "DateTimeReturned",
      RawTitle: "DateTimeReturned",
      Type: "Date",
      Value: dayjs().format("YYYY-MM-DD"),
      IsVisible: true,
      IsReadOnly: true,
      ColumnWidth: 2,
      MaxDate: dayjs().format("YYYY-MM-DD"),
      Ranking: 90,
    },
    DateTimeRentalEnd: {
      Name: "DateTimeRentalEnd",
      RawTitle: "DateTimeRentalEnd",
      Type: "Date",
      Value: dayjs().format("YYYY-MM-DD"),
      IsVisible: true,
      IsReadOnly: false,
      ColumnWidth: 2,
      Ranking: 100,
    },
  };

  if (DisplayBusinessHourEnd)
    returnItemFormFields.BusinessHourEnd = {
      Name: "BusinessHourEnd",
      RawTitle: "BusinessHourEnd",
      Type: "Select",
      Value: null,
      IsVisible: true,
      IsReadOnly: true,
      Dropdown: {
        Items: assignBusinessHoursToDropdown({businessHours}),
      },
      ColumnWidth: 2,
      Ranking: 60,
    };

  returnItemFormFields.ChangeRentalEndDate = {
    Name: "ChangeRentalEndDate",
    RawTitle: "ChangeRentalEndDate",
    Type: "Select",
    Dropdown: {
      Items: [
        {
          Text: translations.AllLinesExceptExpectedEndOrReportedEnd,
          Value: "AllLinesExceptExpectedEndOrReportedEnd",
        },
        {
          Text: translations.AllLinesExceptReportedEnd,
          Value: "AllLinesExceptReportedEnd",
        },
        {
          Text: translations.AllLines,
          Value: "AllLines",
        },
      ],
    },
    Value: {
      Text: translations.AllLinesExceptReportedEnd,
      Value: "AllLinesExceptReportedEnd",
    },
    IsVisible: true,
    IsReadOnly: false,
    IsRequired: true,
    ColumnWidth: 2,
    Ranking: 110,
  };

  if (UseLocations)
    returnItemFormFields.Location = {
      Name: "Location",
      RawTitle: "Location",
      Type: "Select",
      Dropdown: {
        Items: assignLocationsToDropdown({locations}),
      },
      IsVisible: true,
      IsReadOnly: false,
      Value: assingLastSelectedLocationToDropdown({locations, userId}),
      ColumnWidth: 2,
      Ranking: 50,
    };

  if (!DisplayBusinessHourEnd) {
    returnItemFormFields.Notes = {
      Name: "Notes",
      RawTitle: "Notes",
      Type: "Text",
      Value: "",
      IsVisible: true,
      IsReadOnly: false,
      ColumnWidth: 2,
      Ranking: 120,
    };
  }

  // Further field initialization
  if (DefaultSelectedReturnType && !OnlyShowScan) {
    returnItemFormFields.Type.Value = DefaultSelectedReturnType;
    returnItemFormFields.Type.Text = DefaultSelectedReturnType;
    returnItemFormFields.Value.Filter = {ReturnType: DefaultSelectedReturnType};
    returnItemFormFields.Value.ReturnType =
      DefaultSelectedReturnType && DefaultSelectedReturnType !== "All"
        ? DefaultSelectedReturnType
        : null;
  }

  return returnItemFormFields;
};
