import {getTranslations} from "../../session/localstorage/getTranslations";

export const setCorrectAmountForVirtualCompositionRow = ({row, rows}) => {
  if (!row.rowMeta?.virtual) return row;

  const translations = getTranslations();

  const compositionRows = rows.filter(
    (cRow) =>
      cRow.Composition.Value === row.Composition.Value &&
      !cRow.rowMeta?.virtual,
  );

  const readOnly =
    row.Amount.IsReadOnly ??
    compositionRows.some((cRow) => cRow.Amount.IsReadOnly);

  // Group amounts by ItemID
  const amounts = compositionRows.reduce((acc, cRow) => {
    const uniqueAmount = cRow.Amount.Value / cRow.CompositionItem.Value?.Amount;

    if (!acc.has(cRow.ItemID.Value)) {
      acc.set(cRow.ItemID.Value, uniqueAmount);
    } else {
      acc.set(
        cRow.ItemID.Value,
        Math.max(acc.get(cRow.ItemID.Value), uniqueAmount),
      );
    }
    return acc;
  }, new Map());

  const amountValues = Array.from(amounts.values());
  const highestAmount = Math.max(...amountValues);
  const roundedUpAmount = Math.ceil(highestAmount);
  const amountsAreTheSame = amountValues.every(
    (amount) => amount === roundedUpAmount,
  );

  let amountCell = {
    ...row.Amount,
    Value: roundedUpAmount,
    IsReadOnly: readOnly,
    Icon: null,
  };

  if (!amountsAreTheSame) {
    amountCell = {
      ...amountCell,
      Icon: "fa-exclamation-triangle",
      IconColor: "warning",
      IconMessage:
        translations[
          "Rental.OrderItem-MultiEdit-PopupCompositionAmountEditedOnRowTitle"
        ],
      IconSubMessage:
        translations[
          "Rental.OrderItem-MultiEdit-PopupCompositionAmountEditedOnRowDescription"
        ],
    };
  }

  return {
    ...row,
    Amount: amountCell,
  };
};
