import {setInitialDatagridDataToWindow} from "../../../../../functions/datagrid/data/setInitialDatagridDataToWindow";
import getInboundOrder from "../../../../../services/inbound/getInboundOrder";
import getInboundOrderItems from "../../../../../services/inbound/getInboundOrderItems";
import {getColumns} from "../../../../../functions/datagrid/columns/getColumns";
import {getVatCodes} from "../../../../../services/v2/vat-code/getVatCodes";
import {convertKeyValueRowsToCellRows} from "../../../../../functions/datagrid/rows/convertKeyValueRowsToCellRows";
import {getSupplierPriceConditions} from "../../../../../services/supplier/getSupplierPriceConditions";
import getInboundItems from "../../../../../services/inbound/getInboundItems";
import {getUniqueItemIdsFromRows} from "../../../../../functions/datagrid/inbound-orderitem/rows/getUniqueItemIdsFromRows";
import {getSupplier} from "../../../../../services/v2/suppliers/getSupplier";
import {getCostDistributionType} from "../../../../../functions/datagrid/inbound-orderitem/getCostDistributionType";
import {sortColumns} from "../../../../../functions/datagrid/columns/sortColumns";
import {hideInboundOrderItemColumns} from "../../../../../functions/datagrid/inbound-orderitem/hideInboundOrderItemColumns";
import {setClientSideUUIDToRows} from "../../../../../functions/datagrid/rows/setClientSideUUIDToRows";
import {processRowsMetadata} from "../../../../../functions/datagrid/inbound-orderitem/rows/processRowsMetadata";
import {validateInboundValue} from "../../../../../functions/datagrid/inbound-orderitem/rows/validateInboundValue";
import {sortRows} from "../../../../../functions/datagrid/rows/sortRows";
import {sanitizeServerRows} from "../../../../../functions/datagrid/sanitizeServerRows";
import {processColumnsMetaData} from "../../../../../functions/datagrid/inbound-orderitem/processColumnsMetadata";
import {cloneDeep} from "lodash";

export const initializeDatagridInboundOrderItem = async ({vueInstance}) => {
  vueInstance.orderId =
    vueInstance.rWindow.criteria?.[0]?.OrderID ??
    vueInstance.rParentWindow.criteria?.[0]?.OrderID;

  const [
    inboundOrderResponse,
    inboundOrderItemsResponse,
    dgColumns,
    vatCodesResponse,
  ] = await Promise.all([
    getInboundOrder({param: vueInstance.orderId}),
    getInboundOrderItems({orderId: vueInstance.orderId}),
    getColumns({
      table: "Inbound.OrderItem",
      primaryKey: "OrderID",
      prefix: "MultiEdit",
    }),
    getVatCodes(),
  ]);

  vueInstance.vatCodes = vatCodesResponse.data?.Collection ?? [];
  vueInstance.inboundOrder = inboundOrderResponse.data;
  console.log(vueInstance.inboundOrder);
  vueInstance.inboundOrderItems = inboundOrderItemsResponse.data.Collection;
  if (!vueInstance.inboundOrder.SupplierID?.Key) {
    // there is no supplier, so the items can not be edited
    vueInstance.loaded = true;
    return;
  }

  let rows = convertKeyValueRowsToCellRows(vueInstance.inboundOrderItems);

  const [priceConditionsResponse, inboundItemsResponse, supplierResponse] =
    await Promise.all([
      getSupplierPriceConditions({
        supplierId: vueInstance.inboundOrder.SupplierID.Key,
      }),
      getInboundItems({params: {ItemID: getUniqueItemIdsFromRows({rows})}}),
      getSupplier({supplierID: vueInstance.inboundOrder.SupplierID.Key}),
    ]);

  vueInstance.supplier = supplierResponse.data;
  vueInstance.priceConditions = priceConditionsResponse.data.Collection;
  vueInstance.priceConditions.sort(
    (a, b) => (a.Ranking ?? 0) - (b.Ranking ?? 0),
  );

  vueInstance.inboundItems = inboundItemsResponse.data.Collection;

  vueInstance.costDistributionType = getCostDistributionType({
    supplier: vueInstance.supplier,
    settings: vueInstance.settings,
  });

  vueInstance.columns = sortColumns(
    hideInboundOrderItemColumns({
      columns: dgColumns,
      costDistributionType: vueInstance.costDistributionType,
      allowDistributionOfCostsOnInboundOrders:
        vueInstance.settings?.AllowDistributionOfCostsOnInboundOrders ?? false,
    }),
  );

  rows = setClientSideUUIDToRows({rows});
  rows = processRowsMetadata({rows, columns: vueInstance.columns});
  rows = validateInboundValue({
    rows: rows,
    costDistributionType: vueInstance.costDistributionType,
  });

  vueInstance.rows = sortRows(rows);

  await sanitizeServerRows({rows, columns: vueInstance.columns});
  vueInstance.columns = processColumnsMetaData({columns: vueInstance.columns});
  vueInstance.originalRows = cloneDeep(vueInstance.rows);

  const columnsObject = vueInstance.columns.reduce((acc, column) => {
    acc[column.Name] = column;
    return acc;
  }, {});

  setInitialDatagridDataToWindow({
    window: vueInstance.rWindow,
    columns: columnsObject,
    rows: vueInstance.rows,
    vueInstance,
  });

  vueInstance.loaded = true;
};
