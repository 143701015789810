import {saveInvoiceItems} from "../../../../../functions/datagrid/data/saveInvoiceItems";
import {notify} from "../../../../../util/notify";

export const saveDatagridInvoiceItem = async ({vueInstance}) => {
  if (!vueInstance.loaded) return false;
  vueInstance.$store.state.loading = true;
  document.activeElement.blur();

  while (vueInstance.loading || vueInstance.loadingPage) {
    await new Promise((r) => setTimeout(r, 0));
  }

  const currentDirtyState = vueInstance.rWindow.data.dirty;
  vueInstance.$emit("data-change", {
    windowId: vueInstance.rWindow.id,
    newData: {
      ...vueInstance.rWindow.data,
      dirty: false,
    },
  });

  const success = await saveInvoiceItems({
    invoiceItemRows: vueInstance.rows.filter((row) => !row.rowMeta?.virtual),
    invoice: vueInstance.invoice,
  });

  if (!success)
    vueInstance.$emit("data-change", {
      windowId: vueInstance.rWindow.id,
      newData: {
        ...vueInstance.rWindow.data,
        dirty: currentDirtyState,
      },
    });
  if (success) {
    notify({
      message: vueInstance.translations["SaveSuccessful"],
      type: "success",
    });
  }
};
