import {generateNewRowFromScanItem} from "@/functions/datagrid/order-item/row/generateNewRowFromScanItem";
import {processRowPriceCalculation} from "./processRowPriceCalculation";

export async function handleItemIdChange({row, oldRow, order}) {
  row.SerialID = {Value: ""};
  const item = {
    type: "Item",
    value: row.ItemID.Value,
  };

  if (row.ItemID.OriginalValue === row.ItemID.Value) return row;

  const [newRow] = await generateNewRowFromScanItem({
    item,
    ranking: row.Ranking.Value,
    order,
    oldRow: oldRow,
  });

  row = await processRowPriceCalculation({row: newRow, order});

  return row;
}
