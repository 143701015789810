<template>
  <r-select
    :options="options"
    :placeholder="`${translations.translations.Search ?? ''}`"
    :no-options-found-placeholder="translations.translations.NoRowsFound"
    :disable-dropdown="isSearchEmpty"
    :has-calculated-position="true"
    :append-to-body="true"
    :clearable="false"
    :loading="loading"
    input-id="quickActionSearch"
    @focus-lost="handleFocusLoss"
    @search="fetchOptions"
    @keyup-enter="selectOptionEnter"
    @option-selected="selectOption"
  />
</template>

<script>
import {quickSearch} from "../../services/quickSearch";
import RSelect from "../elements/RSelect.vue";
import Window from "../../model/window";

export default {
  components: {
    RSelect,
  },
  inject: ["translations"],
  props: {
    settings: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      options: [],
      loading: false,
      requestData: null,
      newFetchData: null,
      searchValue: null,
    };
  },
  computed: {
    isSearchEmpty() {
      return this.searchValue === "" || this.searchValue === null;
    },
  },
  methods: {
    async fetchOptions(event) {
      this.searchValue = event;
      if (!event || event === "") return (this.options = []);

      if (this.loading) {
        this.newFetchData = event;
        return;
      }

      this.loading = true;
      const requestData = await quickSearch({
        searchValue: this.parseSearchValue(event),
      });
      this.loading = false;

      const newOptions = [];
      const seenValues = new Set();

      for (const searchResult of requestData) {
        const value = searchResult.Value;
        if (!seenValues.has(value)) {
          newOptions.push({Text: searchResult.Text, Value: value});
          seenValues.add(value);
        }
      }

      this.options = newOptions;

      if (this.newFetchData !== null) {
        const newFetchData = this.newFetchData;
        this.newFetchData = null;

        await this.fetchOptions(newFetchData);
      }
    },
    async selectOption(event) {
      if (!event || event.Value === "") return;

      let window = new Window(global.session);
      this.options = [];

      try {
        this.$store.state.loading = true;
        window.customTitle = "<i class='fas fa-spinner fa-spin'>";

        const searchValue = this.settings.ScanFieldFilterValue
          ? event.Value?.replace(this.settings.ScanFieldFilterValue, "")
          : event.Value;

        let output = await global.session.request(
          "/Admin/WebServices/CoreWebServices.asmx/OpenNodeByValue",
          {
            value: searchValue,
            description: event.Text ?? "",
          },
        );

        await window.process(output);
        window.customTitle = null;
        window.loading = false;
        window.focus();
        await window.render();
        this.$store.commit("refreshTabs");
        this.$store.commit("updateWindow");
      } catch (err) {
        console.error(err);
        window.dispose();
      } finally {
        this.$store.state.loading = false;
      }
    },
    async selectOptionEnter() {
      this.options = [];

      const parsedSearchValue = this.parseSearchValue(this.searchValue);

      while (this.loading) {
        await new Promise((resolve) => setTimeout(resolve, 100));
      }

      const event = this.options?.find((option) =>
        option.Value.includes(parsedSearchValue),
      );

      await this.selectOption(event);
    },
    parseSearchValue(value) {
      return value?.replace(this.settings.ScanFieldFilterValue ?? "", "");
    },
    handleFocusLoss() {
      this.options = [];
    },
  },
};
</script>

<style></style>
