<template>
  <div class="min-w-[100px] h-[33px]">
    <r-select
      :value="pageSize"
      :options="pageSizes"
      :required="true"
      @input="$emit('change', $event)"
    />
  </div>
</template>
<script>
import RSelect from "../../elements/RSelect.vue";

export default {
  name: "DatagridPageSize",
  components: {
    RSelect,
  },
  props: {
    pageSize: {
      type: Number,
      default: 0,
    },
    pageSizes: {
      type: Array,
      default: () => [5, 10, 25],
    },
  },
};
</script>
