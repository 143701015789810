import {putInboundInvoiceItems} from "../../../../../services/v2/inbound/invoice/putInboundInvoiceItems";
import {convertCellRowsToKeyValueRows} from "../../../../../functions/datagrid/rows/convertCellRowsToKeyValueRows";
import {notify} from "@/util/notify";

export const saveDatagridInboundInvoiceItem = async ({vueInstance}) => {
  const currentDirtyState = vueInstance.rWindow.data.dirty;
  vueInstance.$emit("data-change", {
    windowId: vueInstance.rWindow.id,
    newData: {
      ...vueInstance.rWindow.data,
      dirty: false,
    },
  });
  const rows = convertCellRowsToKeyValueRows({rows: vueInstance.rows});

  const response = await putInboundInvoiceItems({
    items: rows.filter((row) => !row.rowMeta?.virtual),
    invoiceId: vueInstance.invoice.InvoiceID,
  });

  if (response.status === 200) {
    notify({
      message: vueInstance.translations["SaveSuccessful"],
      type: "success",
    });
    global.session.windows[vueInstance.rWindow.id].parent.action(
      "General",
      "Inbound.Invoice",
      "/Admin/WebServices/CoreWebServices.asmx",
      "OpenDefault",
    );
  } else {
    vueInstance.$emit("data-change", {
      windowId: vueInstance.rWindow.id,
      newData: {
        ...vueInstance.rWindow.data,
        dirty: currentDirtyState,
      },
    });
    notify({
      message:
        response?.data?.Error?.Message ??
        vueInstance.translations["SaveFailed"],
      type: "warning",
    });
  }
};
