import {applyItemDescriptionToRows} from "../../../../../functions/datagrid/stock-transfer-item/applyItemDescriptionToRows";
import {getColumns} from "../../../../../functions/datagrid/columns/getColumns";
import {cloneDeep} from "lodash";
import {processColumnSettings} from "../../../../../functions/datagrid/columns/processColumnSettings";
import {sortColumns} from "../../../../../functions/datagrid/columns/sortColumns";
import {setClientSideUUIDToRows} from "../../../../../functions/datagrid/rows/setClientSideUUIDToRows";
import {rowsSetNullValueForMissingProps} from "../../../../../functions/datagrid/rows/rowsSetNullValueForMissingProps";
import {processColumnsMetaData} from "../../../../../functions/datagrid/stock-transfer-item/processColumnsMetaData";
import {assignValuesToRowProps} from "../../../../../functions/datagrid/stock-transfer-item/assignValuesToRowProps";
import {setInitialDatagridDataToWindow} from "../../../../../functions/datagrid/data/setInitialDatagridDataToWindow";

export const initializeDatagridStockTransferItem = async ({vueInstance}) => {
  vueInstance.criteria =
    vueInstance.rWindow.criteria ?? vueInstance.rParentWindow.criteria;
  vueInstance.stockTransferId = vueInstance.criteria[0].StockTransferID;

  let rows =
    vueInstance.originalRows.length > 0
      ? vueInstance.originalRows
      : vueInstance.rWindow.data.Rows;
  rows = applyItemDescriptionToRows({rows});

  let columns = await getColumns({
    table: "Warehouse.StockTransferItem",
    prefix: "MultiEdit",
    primaryKey: "StockTransferID",
    request: vueInstance.criteria,
  });

  columns = processColumnSettings(columns);
  columns = sortColumns(columns);

  rows = setClientSideUUIDToRows({rows});
  rows = rowsSetNullValueForMissingProps({rows, columns});

  vueInstance.rows = rows;
  rows = await vueInstance.loadAndApplyRowDataForPage();

  await new Promise((r) => setTimeout(r, 0));
  vueInstance.columns = [].concat(
    await processColumnsMetaData({
      columns,
      settings: vueInstance.settings,
    }),
  );

  vueInstance.rows = rows;
  vueInstance.originalRows = cloneDeep(vueInstance.rows);
  vueInstance.initialRows = assignValuesToRowProps({rows: vueInstance.rows});

  setInitialDatagridDataToWindow({
    window: vueInstance.rWindow,
    columns: vueInstance.columns.reduce((acc, column) => {
      acc[column.Name] = column;
      return acc;
    }, {}),
    rows: vueInstance.rows,
    vueInstance: vueInstance,
  });

  vueInstance.loaded = true;
};
