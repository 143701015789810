import {formatRow} from "../../../../../functions/datagrid/pick-list-item/save/formatRow";
import {patchItems} from "../../../../../functions/datagrid/pick-list-item/save/patchItems";
import {checkPickStatus} from "../../../../../functions/datagrid/pick-list-item/save/checkPickStatus";
import {setPicklistData} from "../../../../../functions/datagrid/pick-list-item/setPicklistData";
import {cloneDeep} from "lodash";

export const saveDatagridPickListItem = async ({vueInstance}) => {
  if (!vueInstance.loaded) return false;
  vueInstance.$store.state.loading = true;
  const currentDirtyState = vueInstance.rWindow.data.dirty;
  vueInstance.$emit("data-change", {
    windowId: vueInstance.rWindow.id,
    newData: {
      ...vueInstance.rWindow.data,
      dirty: false,
    },
  });

  const formattedRows = vueInstance.rows.flatMap((row) => formatRow({row}));

  const result = await patchItems(formattedRows);
  if (result !== "Error") {
    await checkPickStatus({
      rows: vueInstance.rows,
      pickListId: vueInstance.pickListId,
      windowId: vueInstance.rWindow.id,
      translations: vueInstance.translations,
    });

    const {rows, columns} = await setPicklistData({
      pickListId: vueInstance.pickListId,
      criteria: vueInstance.criteria,
      settings: vueInstance.settings,
    });

    vueInstance.rows = rows;
    vueInstance.columns = columns;
    vueInstance.originalRows = cloneDeep(vueInstance.rows);
  } else {
    vueInstance.$emit("data-change", {
      windowId: vueInstance.rWindow.id,
      newData: {
        ...vueInstance.rWindow.data,
        dirty: currentDirtyState,
      },
    });
  }
};
