import {initializeReturnItemFormFields} from "./initializeReturnItemFormFields";
import {getColumns} from "../../../../../functions/datagrid/columns/getColumns";
import {processColumnTypes} from "../../../../../functions/datagrid/processColumnTypes";
import {processColumnsMetaData} from "../../../../../functions/datagrid/return-item/processColumnsMetaData";
import {processReturnItemAdvancedColumns} from "../../../../../functions/datagrid/return-item/processReturnItemAdvancedColumns";
import {sortColumns} from "../../../../../functions/datagrid/columns/sortColumns";
import {handleReturnWindowOpenedFromOrder} from "../../../../../functions/datagrid/return-item/handleReturnWindowOpenedFromOrder";

export const initializeDatagridReturnItem = async ({vueInstance}) => {
  ({
    OnlyAutoCheckIfAmountEqualsAmountToReturn:
      vueInstance.OnlyAutoCheckIfAmountEqualsAmountToReturn,
    OnlyShowScan: vueInstance.OnlyShowScan,
  } = vueInstance.rWindow.data);

  vueInstance.formFields = await initializeReturnItemFormFields({
    window: vueInstance.rWindow,
    settings: vueInstance.settings,
    translations: vueInstance.translations,
  });

  let columns = await getColumns({
    table: "Rental.virtual_Return",
    prefix: "New",
  });

  columns = processColumnTypes({columns});
  columns = processColumnsMetaData({columns});
  columns = processReturnItemAdvancedColumns({columns});
  vueInstance.columns = sortColumns(columns);

  const windowCriteria = vueInstance.rWindow.criteria?.[0] ?? null;
  if (windowCriteria && Object.keys(windowCriteria)[0] === "Order") {
    await handleReturnWindowOpenedFromOrder({
      vueComponent: vueInstance,
      criteria: windowCriteria,
    });
  }

  vueInstance.loaded = true;
};
