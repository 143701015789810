import {assignValuesToRowProps} from "../../../../../functions/datagrid/stock-transfer-item/assignValuesToRowProps";
import {assignDeletedRows} from "../../../../../functions/datagrid/stock-transfer-item/assignDeletedRows";
import {convertDatagridRowsToDatagridScanRows} from "../../../../../functions/datagrid/stock-transfer-item/convertDatagridRowsToDatagridScanRows";
import {handleDatagridService} from "../../../../../services/table/handleDatagridService";

export const saveDatagridStockTransferItem = async ({vueInstance}) => {
  const valueAssignedRows = assignValuesToRowProps({rows: vueInstance.rows});

  const deletedAssignedRows = assignDeletedRows({
    initialRows: vueInstance.initialRows,
    newRows: valueAssignedRows,
  });

  const requestData = {
    data: [
      ...convertDatagridRowsToDatagridScanRows({
        rows: deletedAssignedRows,
      }),
    ],
    subject: "Warehouse.StockTransferItem",
    windowId: vueInstance.rWindow.id,
  };

  const currentDirtyState = vueInstance.rWindow.data.dirty;
  vueInstance.$emit("data-change", {
    windowId: vueInstance.rWindow.id,
    newData: {
      ...vueInstance.rWindow.data,
      dirty: false,
    },
  });

  const result = await handleDatagridService({
    operation: "saveTable",
    requestData,
  });

  if (result.status === 500)
    vueInstance.$emit("data-change", {
      windowId: vueInstance.rWindow.id,
      newData: {
        ...vueInstance.rWindow.data,
        dirty: currentDirtyState,
      },
    });
};
