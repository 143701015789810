import {convertCellRowsToKeyValueRows} from "../../../../../functions/datagrid/rows/convertCellRowsToKeyValueRows";
import {setAmountToDeliveryAmountKeyValueRows} from "../../../../../functions/datagrid/undelivered-order-items/setAmountToDeliveryAmountKeyValueRows";
import reloadRelatedWindows from "../../../../../actions/reloadRelatedWindows";
import closeActiveWindow from "../../../../../actions/closeActiveWindow";
import {processInboundDelivery} from "../../../../../services/v2/inbound/delivery/processInboundDelivery";
import {initializeDatagridInboundDelivery} from "./initializeDatagridInboundDelivery";
import {openInboundPutAway} from "../../../../../functions/datagrid/undelivered-order-items/openInboundPutAway";
import {notify} from "@/util/notify";

export const saveDatagridInboundDelivery = async ({vueInstance}) => {
  const currentDirtyState = vueInstance.rWindow.data.dirty;
  vueInstance.$emit("data-change", {
    windowId: vueInstance.rWindow.id,
    newData: {
      ...vueInstance.rWindow.data,
      dirty: false,
    },
  });

  const rows = convertCellRowsToKeyValueRows({rows: vueInstance.rows});
  const deliveryItems = setAmountToDeliveryAmountKeyValueRows({
    rows,
  }).filter((item) => item.AmountReceived > 0);

  const result = await processInboundDelivery({deliveryItems});
  if (result?.status !== 200) {
    vueInstance.$emit("data-change", {
      windowId: vueInstance.rWindow.id,
      newData: {
        ...vueInstance.rWindow.data,
        dirty: currentDirtyState,
      },
    });
    throw new Error();
  }

  reloadRelatedWindows({Subject: "Inbound.Order"});

  if (vueInstance.orderId) {
    closeActiveWindow();
  } else {
    vueInstance.page = 1;
    vueInstance.rows = [];
    vueInstance.columns = [];

    for (const filter in vueInstance.filters) {
      vueInstance.filters[filter].selectedValues = [];
    }

    await initializeDatagridInboundDelivery({vueInstance: vueInstance});
  }

  notify({
    message: vueInstance.translations.NotifySuccesfullyProcessedDelivery,
    type: "success",
  });

  if (vueInstance.settings.PutAwayPolicy === "StartAutomatically")
    openInboundPutAway({
      deliveryItems,
    });
};
