import {getMinSelectedDateTimeExpectedStart} from "./getMinSelectedDateTimeExpectedStart";
import {getDialogFieldValues} from "../../../../../functions/dialog/getDialogFieldValues";
import openDialog from "../../../../../actions/openDialog";
import dayjs from "dayjs";
import store from "../../../../../../state/store";

export const openDateTimeShippedDialog = ({savePartialDelivery, rows}) => {
  const dialogData = {
    RawTitle: "SelectDateTimeShipped",
    Data: {
      Actions: [
        {
          RawTitle: "Cancel",
          ButtonVariant: "secondary",
          Callback: () => {
            store.commit("dialog/hideDialog");
          },
        },
        {
          RawTitle: "ButtonSend",
          Callback: async (dialogData) => {
            const fields = getDialogFieldValues(dialogData);
            savePartialDelivery({fields});
            store.commit("dialog/hideDialog");
          },
        },
      ],
      Fields: [
        {
          Name: "DateTimeShipped",
          RawTitle: "DateTimeShipped",
          Type: "Date",
          Value: dayjs(getMinSelectedDateTimeExpectedStart({rows})).format(
            "YYYY-MM-DD",
          ),
          ClearOnChange: ["BusinessHour"],
        },
        {
          Name: "BusinessHour",
          RawTitle: "BusinessHourStart",
          Type: "Select",
          Dropdown: {
            Items: null,
            TableName: "Settings.BusinessHour",
            ColumnName: "BusinessHour",
            ExtraKeys: ["WarehouseID", "DateTimeShipped"],
            ExtraCriteria: [],
            PassthroughValues: null,
            OpenRef: true,
          },
        },
      ],
    },
  };

  if (
    rows
      .filter((row) => row.Checked.Value === true)
      .some((oi) => oi.DateTimeRentalStart.Value === null)
  ) {
    dialogData.Data.Fields.push({
      Name: "ChangeRentalStartDate",
      RawTitle: "ChangeRentalStartDate",
      Type: "Boolean",
    });
  }
  openDialog(dialogData);
};
