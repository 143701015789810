<template>
  <div class="bg-white">
    <div v-if="!loaded" class="loader w-full flex justify-center">
      <i class="fas fa-spinner fa-spin text-3xl mt-3"></i>
    </div>
    <div
      v-if="loaded && !inboundOrder.SupplierID?.Key"
      class="w-full flex ml-3 mt-3"
    >
      {{ translations.InboundOrderSupplierRequiredError }}
    </div>
    <div v-if="inboundOrder.SupplierID?.Key" ref="datagrid">
      <datagrid-standalone
        v-if="loaded && columns.length"
        :rows="rows"
        :actions="actions"
        :columns="columns"
        :page="page"
        :page-size="pageSize"
        :sort-by="sortBy"
        :sort-direction="sortDirection"
        :window-id="rWindow.id"
        :user-metadata-key="userMetadataKey"
        :process-context="{
          inboundOrder,
          inboundItems,
          priceConditions,
          columns,
          warehouseId,
          vatCodes,
          supplier,
          costDistributionType,
        }"
        :skeleton-rows="addingItemCount"
        @loading="loading = $event"
        @page-change="handlePageChange({page: $event})"
        @icon-event="handleIconEvent"
        @click-row="updateActiveRow"
        @rows-updated="handleRowsUpdated"
      >
        <template #header>
          <div class="flex justify-end mb-2">
            <div v-if="loaded && showScanner" class="w-full h-[33px]">
              <datagrid-scanner
                label="ItemID"
                :filter-by="
                  (option, label, search) => {
                    return (
                      option.ItemID?.toLowerCase().includes(
                        search.toLowerCase(),
                      ) ||
                      option.Description?.toLowerCase().includes(
                        search.toLowerCase(),
                      )
                    );
                  }
                "
                :params="{orderId: inboundOrder.OrderID}"
                :fetch-data-function="fetchInboundItemsScannerData"
                :settings="settings"
                @select="addItem"
              >
                <template #select-option="{option, searchValue}">
                  <item-scanner-label
                    :option="option"
                    :get-type="getTypeFromScannedRow"
                    :get-label="getLabelFromScannedRow"
                    :search-value="searchValue"
                  />
                </template>
              </datagrid-scanner>
            </div>
            <datagrid-page-size
              class="ml-1"
              :page-size="pageSize"
              @change="handlePageSizeChange"
            />
          </div>
        </template>
        <template #footer>
          <datagrid-inbound-order-item-footer
            :page="page"
            :page-size="pageSize"
            :rows="rows"
            :currency="selectedCurrencyId"
            :loading-page="loading"
            @page-change="handlePageChange({page: $event})"
          />
        </template>
      </datagrid-standalone>
    </div>
  </div>
</template>

<script>
import {initializeDatagridInboundOrderItem} from "./utils/initializeDatagridInboundOrderItem";
import {getEmptyRequiredCellNamesPerRow} from "../../../../functions/datagrid/rows/getEmptyRequiredCellNamesPerRow";
import {saveDatagridInboundOrderItem} from "./utils/saveDatagridInboundOrderItem";
import {confirmDiscardUnsavedChanges} from "../../../../interface/prompts/confirmDiscardUnsavedChanges.js";
import fetchInboundItemsScannerData from "../../../../functions/datagrid/inbound-orderitem/fetchInboundItemsScannerData.js";
import {detectDatagridDataChanges} from "../../../../functions/datagrid/detectDatagridDataChanges.js";
import {getUniqueItemIdsFromRows} from "../../../../functions/datagrid/inbound-orderitem/rows/getUniqueItemIdsFromRows";
import {convertRowToliRowDataRow} from "../../../../functions/datagrid/order-item/row/convertRowToliRowDataRow.js";
import {correctRowInboundValue} from "../../../../functions/datagrid/inbound-orderitem/rows/correctRowInboundValue";
import {getLabelFromScannedRow} from "../../../../functions/datagrid/inbound-orderitem/getLabelFromScannedRow";
import {getTypeFromScannedRow} from "../../../../functions/datagrid/inbound-orderitem/getTypeFromScannedRow";
import {validateInboundValue} from "../../../../functions/datagrid/inbound-orderitem/rows/validateInboundValue";
import {mapActionComponent} from "../../../../functions/datagrid/mapActionComponent";
import {getActiveWarehouse} from "../../../../util/getActiveWarehouse";
import {handleJobExecution} from "../../../../functions/datagrid/actions/handleJobExecution.js";
import {handleRowsUpdated} from "../../../../functions/datagrid/inbound-orderitem/handleRowsUpdated";
import {distributeCosts} from "../../../../functions/datagrid/inbound-orderitem/rows/distributeCosts";
import {getTranslations} from "../../../../functions/session/localstorage/getTranslations";
import getInboundItems from "../../../../services/inbound/getInboundItems";
import {getUserId} from "../../../../functions/session/getUserId";
import {sortRows} from "../../../../functions/datagrid/rows/sortRows";
import {getRows} from "../../../../functions/datagrid/inbound-orderitem/getRows";
import {notify} from "../../../../util/notify";

import DatagridInboundOrderItemFooter from "../../datagridFooters/DatagridInboundOrderItemFooter.vue";
import DatagridStandalone from "../../DatagridStandalone.vue";
import DatagridPageSize from "../../datagridFilters/DatagridPageSize.vue";
import ItemScannerLabel from "../../../item/ItemScannerLabel.vue";
import DatagridScanner from "../../DatagridScanner.vue";

export default {
  name: "DatagridInboundOrderItem",
  components: {
    DatagridInboundOrderItemFooter,
    DatagridStandalone,
    DatagridPageSize,
    ItemScannerLabel,
    DatagridScanner,
  },
  provide() {
    return {translations: this.translations};
  },
  props: {
    rWindow: {
      type: Object,
      required: true,
    },
    rParentWindow: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    settings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
      rows: [],
      originalRows: [],
      columns: {},
      sortBy: "Ranking",
      sortDirection: "asc",
      inboundOrder: {},
      vatCodes: [],
      inboundOrderItems: {},
      supplier: {},
      orderId: null,
      activeRow: null,
      selectedItemId: null,
      loading: false,
      scanAmount: 1,
      inboundItems: [],
      page: 1,
      addingItemCount: 0,
      pageSize: 10,
      missingCellRowsObject: {},
      priceConditions: [],
      costDistributionType: "Disable",
      translations: getTranslations(),
      defaultCurrencyId: global.session?.defaultCurrencyId,
      actions: [{type: "Delete"}].map(mapActionComponent),
    };
  },
  computed: {
    warehouseId() {
      return getActiveWarehouse();
    },
    showScanner() {
      return this.inboundOrder.StatusCode === "100";
    },
    userMetadataKey() {
      const userId = getUserId();
      return `${userId}-Inbound.OrderItem-Multi`;
    },
    selectedCurrencyId() {
      return (
        this.rWindow.request.Data?.ClientCurrency ?? this.defaultCurrencyId
      );
    },
  },
  async created() {
    global.eventBus.on(`new-job-${this.rWindow.id}`, this.handleJobExecution);
    await initializeDatagridInboundOrderItem({vueInstance: this});
  },
  beforeDestroy() {
    global.eventBus.off(`new-job-${this.rWindow.id}`, this.handleJobExecution);
  },
  methods: {
    async addItem(scannedItem) {
      const item = {...scannedItem};
      const inboundOrder = this.inboundOrder;
      const datagridElement =
        this.$refs.datagrid.querySelector(".datagrid-table");

      this.addingItemCount = 1;

      this.rows = await getRows({
        columns: this.columns,
        scannedRow: item,
        rows: this.rows,
        inboundOrder: inboundOrder,
        inboundItems: [...this.inboundItems, {...item}],
        priceConditions: this.priceConditions,
        warehouseId: getActiveWarehouse(),
        vatCodes: this.vatCodes,
        supplier: this.supplier,
        costDistributionType: this.costDistributionType,
      });

      this.inboundItems = (
        await getInboundItems({
          params: {ItemID: getUniqueItemIdsFromRows({rows: this.rows})},
        })
      ).data.Collection;

      this.addingItemCount = 0;

      this.scrollToBottom(datagridElement);
      this.updateWindowData();
      this.navigateToLastPage();
    },
    async reset(job) {
      try {
        const rowsAreEqual =
          JSON.stringify(this.rWindow.data.Rows) ===
          JSON.stringify(this.rWindow.initialData.Rows);

        if (!rowsAreEqual && !(await confirmDiscardUnsavedChanges())) {
          return false;
        }
        this.$store.state.loading = true;

        await initializeDatagridInboundOrderItem({vueInstance: this});
      } finally {
        this.$store.state.loading = false;
        this.$emit("job-completed", job);
      }
      return false;
    },
    async save(job) {
      try {
        await saveDatagridInboundOrderItem({vueInstance: this});
      } finally {
        this.$store.state.loading = false;
        this.$emit("job-completed", job);
      }
      return false;
    },
    async sortRows(job) {
      this.rows = sortRows(this.rows);
      await notify({message: this.translations["RowsSorted"], type: "success"});
      this.$emit("job-completed", job);
      return false;
    },
    handleRowsUpdated({rows, action}) {
      this.rows = handleRowsUpdated({
        rows,
        action,
        costDistributionType: this.costDistributionType,
      });
      this.updateWindowData();
    },
    updateActiveRow(row) {
      this.activeRow = row;
    },
    updateWindowData() {
      const columnsObject = this.columns.reduce((acc, column) => {
        acc[column.Name] = column;
        return acc;
      }, {});

      const windowData = {
        ...this.rWindow.data,
        Columns: columnsObject,
        Rows: this.rows.map(convertRowToliRowDataRow),
        dirty: detectDatagridDataChanges({
          newRows: this.rows,
          originalRows: this.originalRows,
          columns: this.columns,
        }),
      };

      this.$emit("data-change", {
        windowId: this.rWindow.id,
        newData: windowData,
      });
    },
    processEmptyRequiredCellNames({rows, columns}) {
      return getEmptyRequiredCellNamesPerRow({
        rows,
        columns,
      });
    },
    handleIconEvent({action, row}) {
      if (action === "Distribute") {
        this.rows = validateInboundValue({
          rows: distributeCosts({
            rows: this.rows,
            row,
            costDistributionType: this.costDistributionType,
          }),
        });
      }
      if (action === "CorrectRow") {
        this.rows = validateInboundValue({
          rows: correctRowInboundValue({row, rows: this.rows}),
        });
      }
    },
    async handlePageChange({page}) {
      this.page = page;
    },
    navigateToPage({page}) {
      this.page = page;
    },
    navigateToLastPage() {
      const rowCount = this.rows.filter(
        (row) => !row.rowMeta?.compositionItem,
      ).length;
      this.page = Math.ceil(rowCount / this.pageSize);
    },
    handlePageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.navigateToPage({page: 1});
    },
    scrollToBottom(element) {
      element.scrollTop = element.scrollHeight;
    },
    getTypeFromScannedRow,
    getLabelFromScannedRow,
    fetchInboundItemsScannerData,
    handleJobExecution() {
      for (const job of this.rWindow.jobs) {
        handleJobExecution({job, vueInstance: this});
      }
    },
  },
};
</script>

<style scoped lang="scss">
.form-input {
  padding: 0;
}
.btn-group {
  .input-group-append {
    box-shadow: 1px 1px 1px 0px rgb(0 0 0 / 9%) !important;
    border: 1px solid #ced4da;
    height: 31.5px !important;
    padding-right: 5px !important;
    padding-left: 5px !important;
    margin-left: -2px;
    :hover {
      background-color: #eeeeee;
    }
  }
  .input-group-append:hover {
    background-color: #eeeeee;
    cursor: default;
  }
}
</style>
