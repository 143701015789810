<template>
  <div>
    <button
      v-if="showButton && !eventBasedReset"
      data-window-event="reset"
      :data-window-target="window.id"
      class="btn-link text-dark btn-icon mt-1 mr-2"
    >
      <span class="float-left">
        <i class="fas fa-sync-alt"></i>
      </span>
    </button>
    <button
      v-if="showButton && eventBasedReset"
      class="btn-link text-dark btn-icon mt-1 mr-2"
      @click="handleClick"
    >
      <span class="float-left">
        <i class="fas fa-sync-alt"></i>
      </span>
    </button>
  </div>
</template>

<script>
import {v4 as uuidv4} from "uuid";

export default {
  name: "CanvasResetButton",
  props: {
    window: {
      type: Object,
      required: true,
    },
    activeRWindow: {
      type: Object,
      required: true,
    },
    activeRSubWindow: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    bulkedit: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    showButton() {
      return (
        !this.window.options ||
        (this.window.options.noReset === false &&
          (!this.bulkedit || !this.window.bulkedit))
      );
    },
    eventBasedReset() {
      if (this.activeRSubWindow?.vueComponent) return true;
      return !this.activeRSubWindow?.id && this.activeRWindow.vueComponent;
    },
  },
  methods: {
    handleClick() {
      this.$emit("new-job", {
        id: uuidv4(),
        actionName: "reset",
        dateTimeCreated: new Date(),
        windowId: this.activeRWindow.subWindowId ?? this.activeRWindow.id,
        context: {
          button: {
            ActionName: "Reset",
          },
        },
      });
    },
  },
};
</script>

<style scoped></style>
