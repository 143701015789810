<template>
  <div class="flex justify-between mb-1.5">
    <div v-if="settings.ShowScanAmountField" class="form-field pr-1">
      <div class="btn-group">
        <div class="form-input">
          <input
            :value="scanAmount"
            class="form-element"
            type="text"
            @change="$emit('scan-amount-change', $event)"
          />
        </div>
        <button class="btn btn-secondary input-group-append">
          {{ translations.Amount }}
        </button>
      </div>
    </div>
    <div class="w-full">
      <item-scanner
        :disabled="!loaded"
        @select="$emit('item-select', $event)"
      />
    </div>

    <r-button
      variant="primary"
      class="h-[33px]"
      @click="$emit('dialog-toggle', $event)"
    >
      {{ translations.ItemAvailability }}
    </r-button>
    <datagrid-page-size
      :page-size="pageSize"
      @change="$emit('page-size-change', $event)"
    />
  </div>
</template>
<script>
import DatagridPageSize from "../datagridFilters/DatagridPageSize.vue";
import ItemScanner from "@/components/item/ItemScanner.vue";
import RButton from "../../elements/RButton.vue";

export default {
  name: "DatagridHeaderOrderItem",
  components: {
    DatagridPageSize,
    ItemScanner,
    RButton,
  },
  inject: ["translations"],
  props: {
    pageSize: {
      type: Number,
      default: 0,
    },
    loaded: {
      type: Boolean,
      default: false,
    },
    scanAmount: {
      type: Number,
      default: 1,
    },
    settings: {
      type: Object,
      required: true,
    },
  },
};
</script>
