import {getRowConflicts} from "../../../../../functions/datagrid/order-item/row/getRowConflicts";
import {filterRowsForStockCheck} from "../../../../../functions/datagrid/rows/filterRowsForStockCheck";
import {processRowConflicts} from "../../../../../functions/datagrid/row/processRowConflicts";
import {saveOrderItems} from "../../../../../functions/datagrid/order-item/row/saveOrderItems";
import {notify} from "../../../../../util/notify";

export const saveDatagridOrderItem = async ({vueInstance}) => {
  if (!vueInstance.loaded) return false;

  vueInstance.$store.state.loading = true;
  document.activeElement.blur();

  while (vueInstance.loading || vueInstance.loadingPage) {
    await new Promise((r) => setTimeout(r, 0));
  }

  const rowConflicts = getRowConflicts({
    rows: filterRowsForStockCheck(vueInstance.rows),
  });

  if (rowConflicts.length) {
    await processRowConflicts({rowConflicts});
    return false;
  }

  const currentDirtyState = vueInstance.rWindow.data.dirty;
  vueInstance.$emit("data-change", {
    windowId: vueInstance.rWindow.id,
    newData: {
      ...vueInstance.rWindow.data,
      dirty: false,
    },
  });

  const success = await saveOrderItems({
    orderId: vueInstance.orderId,
    rows: vueInstance.rows.filter((row) => !row.rowMeta?.virtual),
    columns: vueInstance.columns,
  });

  if (!success)
    vueInstance.$emit("data-change", {
      windowId: vueInstance.rWindow.id,
      newData: {
        ...vueInstance.rWindow.data,
        dirty: currentDirtyState,
      },
    });

  if (success)
    notify({
      message: vueInstance.translations["SaveSuccessful"],
      type: "success",
    });
};
