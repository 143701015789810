import {normalizeValueKeyRows} from "../../../../../functions/datagrid/rows/normalizeValueKeyRows";
import {saveQuotationItems} from "../../../../../functions/datagrid/quotation-item/saveQuotationItems";
import {notify} from "../../../../../util/notify";

export const saveDatagridQuotationItem = async ({vueInstance}) => {
  const currentDirtyState = vueInstance.rWindow.data.dirty;
  vueInstance.$emit("data-change", {
    windowId: vueInstance.rWindow.id,
    newData: {
      ...vueInstance.rWindow.data,
      dirty: false,
    },
  });
  // normalize rows replace all {Key,Value} object properties in  the Value property of each row with the Key property
  vueInstance.rows = normalizeValueKeyRows({rows: vueInstance.rows});

  const success = await saveQuotationItems({
    quotation: vueInstance.quotation,
    quotationItemRows: vueInstance.rows.filter((row) => !row.rowMeta?.virtual),
  });

  if (!success)
    vueInstance.$emit("data-change", {
      windowId: vueInstance.rWindow.id,
      newData: {
        ...vueInstance.rWindow.data,
        dirty: currentDirtyState,
      },
    });
  if (success)
    notify({
      message: vueInstance.translations["SaveSuccessful"],
      type: "success",
    });
};
