<template>
  <div
    class="bg-white"
    :class="{'opacity-60 pointer-events-none': loadingPage}"
  >
    <div
      v-if="!loaded || loadingPage"
      class="loader w-full flex justify-center"
    >
      <i class="fas fa-spinner fa-spin text-3xl mt-3"></i>
    </div>
    <DatagridStandalone
      v-if="loaded && columns.length"
      :rows="rows"
      :columns="columns"
      :window-id="rWindow.id"
      :user-metadata-key="userMetadataKey"
      :page="page"
      :page-size="pageSize"
      :column-value="headerCheckbox"
      @loading="loading = $event"
      @page-change="page = $event"
      @cell-click="handleCellClick($event)"
      @rows-updated="handleRowsUpdated"
      @column-header-change="processColumnHeaderCheckboxClick"
    >
      <template #header>
        <datagrid-header-partial-delivery
          :loaded="loaded"
          :page-size="pageSize"
          @page-size-change="handlePageSizeChange"
        />
      </template>
    </DatagridStandalone>
  </div>
</template>

<script>
import {initializeDatagridPartialDelivery} from "./utils/initializeDatagridPartialDelivery";
import {confirmDiscardUnsavedChanges} from "../../../../interface/prompts/confirmDiscardUnsavedChanges.js";
import {saveDatagridPartialDelivery} from "./utils/saveDatagridPartialDelivery";
import {detectDatagridDataChanges} from "../../../../functions/datagrid/detectDatagridDataChanges.js";
import {handleCheckboxStateChange} from "../../../../functions/datagrid/partialdelivery/handleCheckboxStateChange.js";
import {convertRowToliRowDataRow} from "../../../../functions/datagrid/order-item/row/convertRowToliRowDataRow.js";
import {handleCheckboxCheck} from "../../../../functions/datagrid/partialdelivery/handleCheckboxCheck.js";
import {handleJobExecution} from "../../../../functions/datagrid/actions/handleJobExecution.js";
import {toggleRowsChecked} from "../../../../functions/datagrid/rows/toggleRowsChecked.js";
import {getTranslations} from "../../../../functions/session/localstorage/getTranslations.js";
import {getUserId} from "../../../../functions/session/getUserId";
import getOrder from "../../../../services/orders/getOrder";
import DatagridHeaderPartialDelivery from "../../datagridHeaders/DatagridHeaderPartialDelivery.vue";
import DatagridStandalone from "../../DatagridStandalone.vue";

export default {
  Name: "DatagridPartialDelivery",
  components: {
    DatagridHeaderPartialDelivery,
    DatagridStandalone,
  },
  props: {
    rWindow: {
      type: Object,
      required: true,
    },
    rParentWindow: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    settings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loadingPage: false,
      loading: false,
      loaded: false,
      criteria: [],
      columns: {},
      order: {},
      rows: [],
      originalRows: [],
      page: 1,
      pageSize: 100,
      translations: getTranslations(),
      headerCheckbox: {
        Value: false,
        IsReadOnly: false,
      },
    };
  },
  computed: {
    userMetadataKey() {
      const userId = getUserId();
      return `${userId}-Rental.virtual_PartialDelivery-Multi`;
    },
  },
  async created() {
    global.eventBus.on(`new-job-${this.rWindow.id}`, this.handleJobExecution);
    this.criteria = this.rWindow.criteria ?? this.rParentWindow.criteria;
    this.order = await getOrder({
      orderId: this.criteria[0].OrderID,
    });

    await initializeDatagridPartialDelivery({vueInstance: this});
  },
  beforeDestroy() {
    global.eventBus.off(`new-job-${this.rWindow.id}`, this.handleJobExecution);
  },
  methods: {
    async save(job) {
      try {
        await saveDatagridPartialDelivery({vueInstance: this});
      } finally {
        this.$store.state.loading = false;
        this.$emit("job-completed", job);
      }
      return false;
    },
    async reset(job) {
      try {
        const rowsAreEqual =
          JSON.stringify(this.rWindow.data.Rows) ===
          JSON.stringify(this.rWindow.initialData.Rows);
        if (!rowsAreEqual && !(await confirmDiscardUnsavedChanges())) {
          return false;
        }

        this.$store.state.loading = true;
        await initializeDatagridPartialDelivery({vueInstance: this});
      } finally {
        this.$store.state.loading = false;
        this.$emit("job-completed", job);
      }
      return false;
    },
    handleRowsUpdated({rows}) {
      this.rows = rows;
      this.updateWindowData();
    },
    updateWindowData() {
      this.headerCheckbox.Value = this.rows.every((row) => row.Checked.Value);

      const windowData = {
        ...this.rWindow.data,
        Columns: this.columns.reduce((acc, column) => {
          acc[column.Name] = column;
          return acc;
        }, {}),
        Rows: this.rows.map(convertRowToliRowDataRow),
        dirty: detectDatagridDataChanges({
          newRows: this.rows,
          originalRows: this.originalRows,
          columns: this.columns,
        }),
      };

      this.$emit("data-change", {
        windowId: this.rWindow.id,
        newData: windowData,
      });
    },
    handleCellClick({row, column}) {
      if (
        (column.IsReadOnly || column.Name === "Checked") &&
        column.Name !== "Option"
      ) {
        let newRows = handleCheckboxCheck({
          rows: this.rows,
          row,
        });

        setTimeout(() => {
          this.rows = handleCheckboxStateChange({rows: newRows});
          this.updateWindowData();
        }, 130);
      }
    },
    async processColumnHeaderCheckboxClick(event) {
      if (event.column.Name === "Checked") {
        this.rows = toggleRowsChecked({
          rows: this.rows,
          value: event.value.Value,
        });
        this.updateWindowData();
      }
    },
    handlePageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.navigateToPage({page: 1});
    },
    navigateToPage({page}) {
      this.page = page;
    },
    handleJobExecution() {
      for (const job of this.rWindow.jobs) {
        handleJobExecution({job, vueInstance: this});
      }
    },
  },
};
</script>
