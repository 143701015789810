import {getInvoice} from "../../../../../services/v2/invoices/getInvoice";
import {getInvoiceItems} from "../../../../../services/v2/invoices/getInvoiceItems";
import {getColumns} from "../../../../../functions/datagrid/columns/getColumns";
import {convertKeyValueRowsToCellRows} from "../../../../../functions/datagrid/rows/convertKeyValueRowsToCellRows";
import {setClientSideUUIDToRows} from "../../../../../functions/datagrid/rows/setClientSideUUIDToRows";
import {rowsSetMetadataForCompositionRows} from "../../../../../functions/datagrid/rows/rowsSetMetadataForCompositionRows";
import {rowsGenerateVirtualCompositionRows} from "../../../../../functions/datagrid/rows/rowsGenerateVirtualCompositionRows";
import {processInvoiceItemRowsMetaData} from "../../../../../functions/datagrid/invoice-item/processInvoiceItemRowsMetaData";
import {rowsSetNullValueForMissingProps} from "../../../../../functions/datagrid/rows/rowsSetNullValueForMissingProps";
import {processCompositionDefinition} from "../../../../../functions/datagrid/row/processCompositionDefinition";
import {sortColumns} from "../../../../../functions/datagrid/columns/sortColumns";
import {sortRows} from "../../../../../functions/datagrid/rows/sortRows";
import {cloneDeep} from "lodash";
import {processColumnsMetadata} from "../../../../../functions/datagrid/invoice-item/processColumnsMetadata";
import {setInitialDatagridDataToWindow} from "../../../../../functions/datagrid/data/setInitialDatagridDataToWindow";

export const initializeDatagridInvoiceItem = async ({vueInstance}) => {
  let [invoice, invoiceItemCollection, fetchedColumns] = await Promise.all([
    getInvoice({invoiceId: vueInstance.invoiceId}),
    getInvoiceItems({
      invoiceId: vueInstance.invoiceId,
      include: ["CompositionItem"],
    }),
    getColumns({
      table: "Rental.InvoiceItem",
      prefix: "MultiEdit",
      primaryKey: "InvoiceID",
      criteria: vueInstance.criteria,
    }),
  ]);

  vueInstance.invoice = invoice.data;

  let rows = convertKeyValueRowsToCellRows(
    invoiceItemCollection.data.Collection,
  );
  rows = setClientSideUUIDToRows({rows});
  rows = rowsSetMetadataForCompositionRows({rows});
  rows = rowsGenerateVirtualCompositionRows({rows});
  rows = processInvoiceItemRowsMetaData({rows});
  rows = rowsSetNullValueForMissingProps({rows, columns: fetchedColumns});
  rows = rows.map((row) => processCompositionDefinition({row}));

  vueInstance.columns = fetchedColumns;
  vueInstance.columns = sortColumns(vueInstance.columns);
  vueInstance.rows = sortRows(rows);
  vueInstance.originalRows = cloneDeep(vueInstance.rows);

  vueInstance.columns = processColumnsMetadata({
    columns: vueInstance.columns,
    settings: vueInstance.settings,
  });

  setInitialDatagridDataToWindow({
    window: vueInstance.rWindow,
    columns: vueInstance.columns.reduce((acc, column) => {
      acc[column.Name] = column;
      return acc;
    }, {}),
    rows: vueInstance.rows,
    vueInstance,
  });

  vueInstance.loaded = true;
};
