<template>
  <div>
    <div v-if="tabs?.length" class="tabs sticky-div !block">
      <div class="tab-overflow">
        <a
          v-for="tab in tabs"
          :key="tab.ActionName"
          class="tab"
          :class="{selected: tab.Selected}"
          :action="tab.ActionName"
          :data-window-event="tab.Event"
          data-window-event-preventdefault="false"
          data-window-target="parent"
          >{{ tab.Title }}</a
        >
      </div>
    </div>
    <div>
      <component
        :is="rWindow.vueComponent"
        v-if="
          vueComponent &&
          !rWindow.subWindowId &&
          !activeSubRWindow.vueComponent &&
          !activeSubRWindow.data
        "
        :r-window="rWindow"
        :settings="settings"
        @window-change="$emit('window-change', $event)"
        @data-change="$emit('data-change', $event)"
        @initial-data-change="$emit('initial-data-change', $event)"
        @job-completed="$emit('job-completed', $event)"
      />
      <component
        :is="activeSubRWindow.vueComponent"
        v-if="activeSubRWindow.vueComponent"
        :r-window="activeSubRWindow"
        :r-parent-window="rWindow"
        :settings="settings"
        @window-change="$emit('window-change', $event)"
        @data-change="$emit('data-change', $event)"
        @initial-data-change="$emit('initial-data-change', $event)"
        @job-completed="$emit('job-completed', $event)"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "RWindow",
  components: {},
  props: {
    rWindows: {
      type: Object,
      required: true,
    },
    rWindow: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  computed: {
    tabs() {
      return this.rWindow.tabs;
    },
    activeSubRWindow() {
      if (!this.rWindow.subWindowId) return {};
      return (
        Object.values(this.rWindows).find(
          (rWindow) => rWindow.id === this.rWindow.subWindowId,
        ) ?? {}
      );
    },
    vueComponent() {
      if (this.activeSubRWindow.vueComponent) {
        return this.activeSubRWindow.vueComponent;
      }
      return this.rWindow.vueComponent;
    },
  },
};
</script>
