import {getInboundInvoiceItemData} from "../../../../../functions/datagrid/inbound-invoice-item/getInboundInvoiceItemData";
import {processInvoiceDiscrepancy} from "../../../../../functions/datagrid/inbound-invoice-item/rows/processInvoiceDiscrepancy";
import {readonlyInboundInvoice} from "../../../../../functions/datagrid/inbound-invoice-item/readonlyInboundInvoice";
import {setInitialDatagridDataToWindow} from "../../../../../functions/datagrid/data/setInitialDatagridDataToWindow";
import {cloneDeep} from "lodash";

export const initializeDatagridInboundInvoiceItem = async ({vueInstance}) => {
  const {columns, rows, invoice, supplier, vatCodes} =
    await getInboundInvoiceItemData({
      invoiceId: vueInstance.invoiceId,
      parentWindowCriteria:
        vueInstance.rParentWindow.criteria ?? vueInstance.criteria,
      settings: vueInstance.settings,
    });

  processInvoiceDiscrepancy(rows);

  vueInstance.columns = columns;
  vueInstance.rows = rows;
  vueInstance.invoice = invoice;
  vueInstance.supplier = supplier;
  vueInstance.vatCodes = vatCodes;
  vueInstance.originalRows = cloneDeep(vueInstance.rows);

  if (readonlyInboundInvoice({invoice})) {
    vueInstance.actions = [];
  }

  setInitialDatagridDataToWindow({
    window: vueInstance.rWindow,
    columns: vueInstance.columns.reduce((acc, column) => {
      acc[column.Name] = column;
      return acc;
    }, {}),
    rows: vueInstance.rows,
    vueInstance,
  });

  vueInstance.loaded = true;
};
