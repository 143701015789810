<template>
  <div
    class="bg-white"
    :class="{'opacity-60 pointer-events-none': loadingPage}"
  >
    <div
      v-if="!loaded || loadingPage"
      class="loader w-full flex justify-center"
    >
      <i class="fas fa-spinner fa-spin text-3xl mt-3" />
    </div>
    <div ref="datagrid">
      <datagrid-standalone
        :rows="rows"
        :columns="columns"
        :page="page"
        :page-size="pageSize"
        @page-change="page = $event"
        @rows-updated="handleRowsUpdated"
      >
        <template #header>
          <datagrid-header-pick-list-item
            :page-size="pageSize"
            :loaded="loaded"
            :rows="rows"
            @scan="handleScan"
            @page-size-change="handlePageSizeChange"
          />
        </template>
      </datagrid-standalone>
    </div>
  </div>
</template>

<script>
import {initializeDatagridPickListItem} from "./utils/initializeDatagridPickListItem";
import {confirmDiscardUnsavedChanges} from "../../../../interface/prompts/confirmDiscardUnsavedChanges.js";
import {convertRowToliRowDataRow} from "../../../../functions/datagrid/order-item/row/convertRowToliRowDataRow.js";
import {saveDatagridPickListItem} from "./utils/saveDatagridPickListItem";
import {detectDatagridDataChanges} from "../../../../functions/datagrid/detectDatagridDataChanges.js";
import {serialIDExistenceCheck} from "../../../../functions/datagrid/pick-list-item/scan/serialIDExistenceCheck";
import {handleJobExecution} from "../../../../functions/datagrid/actions/handleJobExecution.js";
import {findRowAndUpdate} from "../../../../functions/datagrid/pick-list-item/scan/findRowAndUpdate";
import {scanErrorHandler} from "../../../../functions/datagrid/pick-list-item/scan/scanErrorHandler";
import {getTranslations} from "../../../../functions/session/localstorage/getTranslations.js";
import DatagridHeaderPickListItem from "../../datagridHeaders/DatagridHeaderPickListItem.vue";
import DatagridStandalone from "../../DatagridStandalone.vue";

export default {
  name: "DatagridPickListItem",
  components: {
    DatagridHeaderPickListItem,
    DatagridStandalone,
  },
  props: {
    rWindow: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pickListItems: [],
      pickListId: null,
      criteria: [],
      rows: [],
      originalRows: [],
      columns: [],
      loaded: false,
      loading: false,
      loadingPage: false,
      page: 1,
      pageSize: 100,
      translations: getTranslations(),
    };
  },
  async created() {
    global.eventBus.on(`new-job-${this.rWindow.id}`, this.handleJobExecution);
    this.criteria = this.rWindow.criteria;
    this.pickListId = this.criteria[0].PickListID;
    await initializeDatagridPickListItem({vueInstance: this});
  },
  beforeDestroy() {
    global.eventBus.off(`new-job-${this.rWindow.id}`, this.handleJobExecution);
  },
  methods: {
    async save(job) {
      try {
        await saveDatagridPickListItem({vueInstance: this});
      } finally {
        this.$store.state.loading = false;
        this.$emit("job-completed", job);
      }
      return false;
    },
    async reset(job) {
      try {
        const rowsAreEqual =
          JSON.stringify(this.rWindow.data.Rows) ===
          JSON.stringify(this.rWindow.initialData.Rows);
        if (!rowsAreEqual && !(await confirmDiscardUnsavedChanges()))
          return false;

        this.$store.state.loading = true;
        await initializeDatagridPickListItem({vueInstance: this});
      } finally {
        this.$store.state.loading = false;
        this.$emit("job-completed", job);
      }
      return false;
    },
    async handleScan(scanData) {
      if (scanErrorHandler({scanData})) return;

      const {ItemID, SerialID} = scanData[0];

      if (serialIDExistenceCheck({rows: this.rows, SerialID})) return;

      this.rows = findRowAndUpdate({rows: this.rows, ItemID, SerialID});

      this.updateWindowData();
      this.navigateToLastPage();
    },
    handleRowsUpdated({rows}) {
      this.rows = rows;
      this.updateWindowData();
    },
    updateWindowData() {
      const windowData = {
        ...this.rWindow.data,
        Columns: this.columns.reduce((acc, column) => {
          acc[column.Name] = column;
          return acc;
        }, {}),
        Rows: this.rows.map(convertRowToliRowDataRow),
        dirty: detectDatagridDataChanges({
          newRows: this.rows,
          originalRows: this.originalRows,
          columns: this.columns,
        }),
      };

      this.$emit("data-change", {
        windowId: this.rWindow.id,
        newData: windowData,
      });
    },
    handlePageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.navigateToPage({page: 1});
    },
    navigateToLastPage() {
      const rowCount = this.rows.filter(
        (row) => !row.rowMeta?.compositionItem,
      ).length;
      this.page = Math.ceil(rowCount / this.pageSize);
    },
    navigateToPage({page}) {
      this.page = page;
    },
    handleJobExecution() {
      for (const job of this.rWindow.jobs) {
        handleJobExecution({job, vueInstance: this});
      }
    },
  },
};
</script>
